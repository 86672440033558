import { Injectable } from '@angular/core';
import { NgProgressRef } from 'ngx-progressbar';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private ref: NgProgressRef;

  constructor() { }

  setLoader(ref: NgProgressRef) {
    this.ref = ref;
  }

  start() {
    this.ref.start()
  }

  complete() {
    this.ref.complete()
  }
}
