import { WebsiteCollaboratorDto, WebsiteInfoDto } from 'src/app/api/models';
export interface SiteOverview {
  id: any;
  name: string;
  slug: string;
  url: string;
  active: boolean;
  pending: boolean;
  collaborators: Array<WebsiteCollaboratorDto>;
  customDomain: string;
  subdomain: string;
  user: string;
}

export class SiteOverview {
  static fromApi(site: WebsiteInfoDto) {
    const instance = new SiteOverview();
    instance.id = site.id;
    instance.name = site.name;
    instance.slug = site.slug;
    instance.url = site.url;
    instance.active = site.active;
    instance.pending = false;
    instance.collaborators = site.collaborators;
    instance.customDomain = site.customDomain;
    instance.subdomain = site.subdomain;
    instance.user = site.user;

    return instance;
  }
}
