/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AppendParticipantRequestDto } from '../models/append-participant-request-dto';
import { CreatePostDto } from '../models/create-post-dto';
import { GetParticipantResponseDto } from '../models/get-participant-response-dto';
import { UpdatePostDto } from '../models/update-post-dto';

@Injectable({ providedIn: 'root' })
export class PostApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `postControllerGetPosts()` */
  static readonly PostControllerGetPostsPath = '/api/post';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postControllerGetPosts()` instead.
   *
   * This method doesn't expect any request body.
   */
  postControllerGetPosts$Response(
    params?: {
      website?: string;
      postIds?: Array<string>;
      title?: string;
      slug?: string;
      category?: string;
      limit?: number;
      offset?: number;
      sortDirection?: 'asc' | 'desc';
      isDraft?: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
}>> {
    const rb = new RequestBuilder(this.rootUrl, PostApi.PostControllerGetPostsPath, 'get');
    if (params) {
      rb.query('website', params.website, {});
      rb.query('postIds', params.postIds, {});
      rb.query('title', params.title, {});
      rb.query('slug', params.slug, {});
      rb.query('category', params.category, {});
      rb.query('limit', params.limit, {});
      rb.query('offset', params.offset, {});
      rb.query('sortDirection', params.sortDirection, {});
      rb.query('isDraft', params.isDraft, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postControllerGetPosts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  postControllerGetPosts(
    params?: {
      website?: string;
      postIds?: Array<string>;
      title?: string;
      slug?: string;
      category?: string;
      limit?: number;
      offset?: number;
      sortDirection?: 'asc' | 'desc';
      isDraft?: boolean;
    },
    context?: HttpContext
  ): Observable<{
}> {
    return this.postControllerGetPosts$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `postControllerCreatePost()` */
  static readonly PostControllerCreatePostPath = '/api/post';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postControllerCreatePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postControllerCreatePost$Response(
    params: {
      body: CreatePostDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
}>> {
    const rb = new RequestBuilder(this.rootUrl, PostApi.PostControllerCreatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postControllerCreatePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postControllerCreatePost(
    params: {
      body: CreatePostDto
    },
    context?: HttpContext
  ): Observable<{
}> {
    return this.postControllerCreatePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `postControllerGetPost()` */
  static readonly PostControllerGetPostPath = '/api/post/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postControllerGetPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  postControllerGetPost$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
}>> {
    const rb = new RequestBuilder(this.rootUrl, PostApi.PostControllerGetPostPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postControllerGetPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  postControllerGetPost(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<{
}> {
    return this.postControllerGetPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `postControllerDeletePost()` */
  static readonly PostControllerDeletePostPath = '/api/post/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postControllerDeletePost()` instead.
   *
   * This method doesn't expect any request body.
   */
  postControllerDeletePost$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
}>> {
    const rb = new RequestBuilder(this.rootUrl, PostApi.PostControllerDeletePostPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postControllerDeletePost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  postControllerDeletePost(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<{
}> {
    return this.postControllerDeletePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `postControllerUpdatePost()` */
  static readonly PostControllerUpdatePostPath = '/api/post/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postControllerUpdatePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postControllerUpdatePost$Response(
    params: {
      id: string;
      body: UpdatePostDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
}>> {
    const rb = new RequestBuilder(this.rootUrl, PostApi.PostControllerUpdatePostPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postControllerUpdatePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postControllerUpdatePost(
    params: {
      id: string;
      body: UpdatePostDto
    },
    context?: HttpContext
  ): Observable<{
}> {
    return this.postControllerUpdatePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `postControllerGetPostBySlug()` */
  static readonly PostControllerGetPostBySlugPath = '/api/post/slug/{slug}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postControllerGetPostBySlug()` instead.
   *
   * This method doesn't expect any request body.
   */
  postControllerGetPostBySlug$Response(
    params: {
      slug: string;
      website: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
}>> {
    const rb = new RequestBuilder(this.rootUrl, PostApi.PostControllerGetPostBySlugPath, 'get');
    if (params) {
      rb.path('slug', params.slug, {});
      rb.query('website', params.website, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postControllerGetPostBySlug$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  postControllerGetPostBySlug(
    params: {
      slug: string;
      website: string;
    },
    context?: HttpContext
  ): Observable<{
}> {
    return this.postControllerGetPostBySlug$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `postControllerDeleteHeaderBgImg()` */
  static readonly PostControllerDeleteHeaderBgImgPath = '/api/post/{id}/feature_img';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postControllerDeleteHeaderBgImg()` instead.
   *
   * This method doesn't expect any request body.
   */
  postControllerDeleteHeaderBgImg$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
}>> {
    const rb = new RequestBuilder(this.rootUrl, PostApi.PostControllerDeleteHeaderBgImgPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postControllerDeleteHeaderBgImg$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  postControllerDeleteHeaderBgImg(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<{
}> {
    return this.postControllerDeleteHeaderBgImg$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `postControllerListBlogCategories()` */
  static readonly PostControllerListBlogCategoriesPath = '/api/post/{siteId}/categories';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postControllerListBlogCategories()` instead.
   *
   * This method doesn't expect any request body.
   */
  postControllerListBlogCategories$Response(
    params: {
      siteId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<string>>> {
    const rb = new RequestBuilder(this.rootUrl, PostApi.PostControllerListBlogCategoriesPath, 'get');
    if (params) {
      rb.path('siteId', params.siteId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postControllerListBlogCategories$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  postControllerListBlogCategories(
    params: {
      siteId: string;
    },
    context?: HttpContext
  ): Observable<Array<string>> {
    return this.postControllerListBlogCategories$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `postControllerListParticipants()` */
  static readonly PostControllerListParticipantsPath = '/api/post/{siteId}/{id}/participant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postControllerListParticipants()` instead.
   *
   * This method doesn't expect any request body.
   */
  postControllerListParticipants$Response(
    params: {
      id: string;
      siteId: string;
      slug?: string;
      status?: 'APPROVED' | 'PENDING' | 'DECLINED';
      role?: string;
      name?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<GetParticipantResponseDto>>> {
    const rb = new RequestBuilder(this.rootUrl, PostApi.PostControllerListParticipantsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('siteId', params.siteId, {});
      rb.query('slug', params.slug, {});
      rb.query('status', params.status, {});
      rb.query('role', params.role, {});
      rb.query('name', params.name, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GetParticipantResponseDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postControllerListParticipants$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  postControllerListParticipants(
    params: {
      id: string;
      siteId: string;
      slug?: string;
      status?: 'APPROVED' | 'PENDING' | 'DECLINED';
      role?: string;
      name?: string;
    },
    context?: HttpContext
  ): Observable<Array<GetParticipantResponseDto>> {
    return this.postControllerListParticipants$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GetParticipantResponseDto>>): Array<GetParticipantResponseDto> => r.body)
    );
  }

  /** Path part for operation `postControllerSubmitParticipant()` */
  static readonly PostControllerSubmitParticipantPath = '/api/post/{id}/participant/{participantId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postControllerSubmitParticipant()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postControllerSubmitParticipant$Response(
    params: {
      id: string;
      participantId: string;
      body: AppendParticipantRequestDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, PostApi.PostControllerSubmitParticipantPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('participantId', params.participantId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postControllerSubmitParticipant$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postControllerSubmitParticipant(
    params: {
      id: string;
      participantId: string;
      body: AppendParticipantRequestDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.postControllerSubmitParticipant$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `postControllerRemoveParticipant()` */
  static readonly PostControllerRemoveParticipantPath = '/api/post/{id}/participant/{participantId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postControllerRemoveParticipant()` instead.
   *
   * This method doesn't expect any request body.
   */
  postControllerRemoveParticipant$Response(
    params: {
      id: string;
      participantId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, PostApi.PostControllerRemoveParticipantPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('participantId', params.participantId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postControllerRemoveParticipant$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  postControllerRemoveParticipant(
    params: {
      id: string;
      participantId: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.postControllerRemoveParticipant$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
