import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SubscriptionPlan } from '../../models/subscription-plan.model';
import { SubscriptionPlanApi } from '../../../api/services';
import { map, tap } from 'rxjs/operators';
import { CurrencyCodeDto, PaymentIntervalDto, SubscriptionPlanAddonDto } from 'src/app/api/models';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionPlansService {
  private plans: BehaviorSubject<SubscriptionPlan[]> = new BehaviorSubject([]);

  plans$ = this.plans.asObservable()
  monthlyPlans$ = this.plans$.pipe(map(plans => plans.filter(p => p.durationType === 'month')))
  annualyPlans$ = this.plans$.pipe(map(plans => plans.filter(p => p.durationType === 'year')))

  constructor(
    private subscriptionPlanApi: SubscriptionPlanApi
  ) { }

  fetchPlans(): Observable<SubscriptionPlan[]> {
    return this.subscriptionPlanApi.subscriptionPlanControllerGetPlans().pipe(
      map(plans => plans.map(plan => SubscriptionPlan.fromApi(plan))),
      map(plans => this.sortPlansByPrice(plans)),
      tap(plans => this.plans.next(plans))
    )
  }

  listAddons(): Observable<SubscriptionPlanAddonDto[]> {
    return this.subscriptionPlanApi.subscriptionPlanControllerListAddons().pipe(
      map(plans => plans?.sort((a, b) => a.index - b.index))
    )
  }

  private sortPlansByPrice(plans: SubscriptionPlan[]) {
    return ([].concat(plans) as SubscriptionPlan[])
      .sort((a, b) => Math.sign(a.recurringPrice - b.recurringPrice))
  }
}
