/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { CreateEpisodeDto } from '../models/create-episode-dto';
import { DeleteEpisodesRequestBodyDto } from '../models/delete-episodes-request-body-dto';
import { UpdateEpisodeDto } from '../models/update-episode-dto';

@Injectable({ providedIn: 'root' })
export class WebsiteEpisodeApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `websiteEpisodeControllerCreateEpisode()` */
  static readonly WebsiteEpisodeControllerCreateEpisodePath = '/api/website/{websiteId}/episode';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteEpisodeControllerCreateEpisode()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  websiteEpisodeControllerCreateEpisode$Response(
    params: {
      websiteId: string;
      body: CreateEpisodeDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
}>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteEpisodeApi.WebsiteEpisodeControllerCreateEpisodePath, 'post');
    if (params) {
      rb.path('websiteId', params.websiteId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteEpisodeControllerCreateEpisode$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  websiteEpisodeControllerCreateEpisode(
    params: {
      websiteId: string;
      body: CreateEpisodeDto
    },
    context?: HttpContext
  ): Observable<{
}> {
    return this.websiteEpisodeControllerCreateEpisode$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `websiteEpisodeControllerDeleteEpisodes()` */
  static readonly WebsiteEpisodeControllerDeleteEpisodesPath = '/api/website/{websiteId}/episode';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteEpisodeControllerDeleteEpisodes()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  websiteEpisodeControllerDeleteEpisodes$Response(
    params: {
      websiteId: string;
      body: DeleteEpisodesRequestBodyDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteEpisodeApi.WebsiteEpisodeControllerDeleteEpisodesPath, 'delete');
    if (params) {
      rb.path('websiteId', params.websiteId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteEpisodeControllerDeleteEpisodes$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  websiteEpisodeControllerDeleteEpisodes(
    params: {
      websiteId: string;
      body: DeleteEpisodesRequestBodyDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.websiteEpisodeControllerDeleteEpisodes$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `websiteEpisodeControllerEditEpisode()` */
  static readonly WebsiteEpisodeControllerEditEpisodePath = '/api/website/{websiteId}/episode/{episodeId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteEpisodeControllerEditEpisode()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  websiteEpisodeControllerEditEpisode$Response(
    params: {
      websiteId: string;
      episodeId: string;
      body: UpdateEpisodeDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
}>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteEpisodeApi.WebsiteEpisodeControllerEditEpisodePath, 'patch');
    if (params) {
      rb.path('websiteId', params.websiteId, {});
      rb.path('episodeId', params.episodeId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteEpisodeControllerEditEpisode$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  websiteEpisodeControllerEditEpisode(
    params: {
      websiteId: string;
      episodeId: string;
      body: UpdateEpisodeDto
    },
    context?: HttpContext
  ): Observable<{
}> {
    return this.websiteEpisodeControllerEditEpisode$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `websiteEpisodeControllerArchiveEpisode()` */
  static readonly WebsiteEpisodeControllerArchiveEpisodePath = '/api/website/{websiteId}/episode/{episodeId}/archive';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteEpisodeControllerArchiveEpisode()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteEpisodeControllerArchiveEpisode$Response(
    params: {
      websiteId: string;
      episodeId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteEpisodeApi.WebsiteEpisodeControllerArchiveEpisodePath, 'patch');
    if (params) {
      rb.path('websiteId', params.websiteId, {});
      rb.path('episodeId', params.episodeId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteEpisodeControllerArchiveEpisode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteEpisodeControllerArchiveEpisode(
    params: {
      websiteId: string;
      episodeId: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.websiteEpisodeControllerArchiveEpisode$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `websiteEpisodeControllerRestoreArchivedEpisode()` */
  static readonly WebsiteEpisodeControllerRestoreArchivedEpisodePath = '/api/website/{websiteId}/episode/{episodeId}/restore';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteEpisodeControllerRestoreArchivedEpisode()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteEpisodeControllerRestoreArchivedEpisode$Response(
    params: {
      websiteId: string;
      episodeId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteEpisodeApi.WebsiteEpisodeControllerRestoreArchivedEpisodePath, 'post');
    if (params) {
      rb.path('websiteId', params.websiteId, {});
      rb.path('episodeId', params.episodeId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteEpisodeControllerRestoreArchivedEpisode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteEpisodeControllerRestoreArchivedEpisode(
    params: {
      websiteId: string;
      episodeId: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.websiteEpisodeControllerRestoreArchivedEpisode$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
