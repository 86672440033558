/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { CreateUserResponseDto } from '../models/create-user-response-dto';
import { CreateUserWithPasswordDto } from '../models/create-user-with-password-dto';
import { ForgotPasswordDto } from '../models/forgot-password-dto';
import { LoginDto } from '../models/login-dto';
import { LoginResponseDto } from '../models/login-response-dto';
import { LoginWithGoogleDto } from '../models/login-with-google-dto';
import { ResetPasswordDto } from '../models/reset-password-dto';
import { UserDto } from '../models/user-dto';

@Injectable({ providedIn: 'root' })
export class UserApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `userControllerSignUpWithPassword()` */
  static readonly UserControllerSignUpWithPasswordPath = '/api/user/signup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerSignUpWithPassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerSignUpWithPassword$Response(
    params: {
      freeTrial: boolean;
      body: CreateUserWithPasswordDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CreateUserResponseDto>> {
    const rb = new RequestBuilder(this.rootUrl, UserApi.UserControllerSignUpWithPasswordPath, 'post');
    if (params) {
      rb.query('freeTrial', params.freeTrial, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreateUserResponseDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userControllerSignUpWithPassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerSignUpWithPassword(
    params: {
      freeTrial: boolean;
      body: CreateUserWithPasswordDto
    },
    context?: HttpContext
  ): Observable<CreateUserResponseDto> {
    return this.userControllerSignUpWithPassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateUserResponseDto>): CreateUserResponseDto => r.body)
    );
  }

  /** Path part for operation `userControllerGetUser()` */
  static readonly UserControllerGetUserPath = '/api/user';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerGetUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerGetUser$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<UserDto>> {
    const rb = new RequestBuilder(this.rootUrl, UserApi.UserControllerGetUserPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userControllerGetUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerGetUser(
    params?: {
    },
    context?: HttpContext
  ): Observable<UserDto> {
    return this.userControllerGetUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDto>): UserDto => r.body)
    );
  }

  /** Path part for operation `userControllerIsEmailExist()` */
  static readonly UserControllerIsEmailExistPath = '/api/user/isEmailExist/{email}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerIsEmailExist()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerIsEmailExist$Response(
    params: {
      email: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, UserApi.UserControllerIsEmailExistPath, 'get');
    if (params) {
      rb.path('email', params.email, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userControllerIsEmailExist$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerIsEmailExist(
    params: {
      email: string;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.userControllerIsEmailExist$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `userControllerLogin()` */
  static readonly UserControllerLoginPath = '/api/user/login';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerLogin()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerLogin$Response(
    params: {
      body: LoginDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<LoginResponseDto>> {
    const rb = new RequestBuilder(this.rootUrl, UserApi.UserControllerLoginPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoginResponseDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userControllerLogin$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerLogin(
    params: {
      body: LoginDto
    },
    context?: HttpContext
  ): Observable<LoginResponseDto> {
    return this.userControllerLogin$Response(params, context).pipe(
      map((r: StrictHttpResponse<LoginResponseDto>): LoginResponseDto => r.body)
    );
  }

  /** Path part for operation `userControllerLoginWithGoogle()` */
  static readonly UserControllerLoginWithGooglePath = '/api/user/login/google';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerLoginWithGoogle()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerLoginWithGoogle$Response(
    params: {
      freeTrial: boolean;
      body: LoginWithGoogleDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<LoginResponseDto>> {
    const rb = new RequestBuilder(this.rootUrl, UserApi.UserControllerLoginWithGooglePath, 'post');
    if (params) {
      rb.query('freeTrial', params.freeTrial, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoginResponseDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userControllerLoginWithGoogle$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerLoginWithGoogle(
    params: {
      freeTrial: boolean;
      body: LoginWithGoogleDto
    },
    context?: HttpContext
  ): Observable<LoginResponseDto> {
    return this.userControllerLoginWithGoogle$Response(params, context).pipe(
      map((r: StrictHttpResponse<LoginResponseDto>): LoginResponseDto => r.body)
    );
  }

  /** Path part for operation `userControllerForgotPassword()` */
  static readonly UserControllerForgotPasswordPath = '/api/user/forgot-password';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerForgotPassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerForgotPassword$Response(
    params: {
      body: ForgotPasswordDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, UserApi.UserControllerForgotPasswordPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userControllerForgotPassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerForgotPassword(
    params: {
      body: ForgotPasswordDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.userControllerForgotPassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `userControllerResetPassword()` */
  static readonly UserControllerResetPasswordPath = '/api/user/reset-password';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerResetPassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerResetPassword$Response(
    params: {
      body: ResetPasswordDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<LoginResponseDto>> {
    const rb = new RequestBuilder(this.rootUrl, UserApi.UserControllerResetPasswordPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoginResponseDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userControllerResetPassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerResetPassword(
    params: {
      body: ResetPasswordDto
    },
    context?: HttpContext
  ): Observable<LoginResponseDto> {
    return this.userControllerResetPassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<LoginResponseDto>): LoginResponseDto => r.body)
    );
  }

  /** Path part for operation `userControllerDeleteAccount()` */
  static readonly UserControllerDeleteAccountPath = '/api/user/account';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerDeleteAccount()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerDeleteAccount$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, UserApi.UserControllerDeleteAccountPath, 'delete');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userControllerDeleteAccount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerDeleteAccount(
    params?: {
    },
    context?: HttpContext
  ): Observable<void> {
    return this.userControllerDeleteAccount$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `userControllerGetBeaconSignature()` */
  static readonly UserControllerGetBeaconSignaturePath = '/api/user/beacon/signature';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerGetBeaconSignature()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerGetBeaconSignature$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, UserApi.UserControllerGetBeaconSignaturePath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userControllerGetBeaconSignature$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerGetBeaconSignature(
    params?: {
    },
    context?: HttpContext
  ): Observable<string> {
    return this.userControllerGetBeaconSignature$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

}
