/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AdminUpdateAffiliateRequestDto } from '../models/admin-update-affiliate-request-dto';
import { CreateAffiliateRequestDto } from '../models/create-affiliate-request-dto';

@Injectable({ providedIn: 'root' })
export class AdminApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminControllerCreateAffiliate()` */
  static readonly AdminControllerCreateAffiliatePath = '/api/admin/affiliate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminControllerCreateAffiliate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminControllerCreateAffiliate$Response(
    params: {
      body: CreateAffiliateRequestDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AdminApi.AdminControllerCreateAffiliatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminControllerCreateAffiliate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminControllerCreateAffiliate(
    params: {
      body: CreateAffiliateRequestDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.adminControllerCreateAffiliate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `adminControllerUpdateAffiliate()` */
  static readonly AdminControllerUpdateAffiliatePath = '/api/admin/affiliate/{affiliateId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminControllerUpdateAffiliate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminControllerUpdateAffiliate$Response(
    params: {
      affiliateId: string;
      body: AdminUpdateAffiliateRequestDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
}>> {
    const rb = new RequestBuilder(this.rootUrl, AdminApi.AdminControllerUpdateAffiliatePath, 'patch');
    if (params) {
      rb.path('affiliateId', params.affiliateId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminControllerUpdateAffiliate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminControllerUpdateAffiliate(
    params: {
      affiliateId: string;
      body: AdminUpdateAffiliateRequestDto
    },
    context?: HttpContext
  ): Observable<{
}> {
    return this.adminControllerUpdateAffiliate$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `adminControllerGetQueueJobs()` */
  static readonly AdminControllerGetQueueJobsPath = '/api/admin/queue/{queue}/jobs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminControllerGetQueueJobs()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminControllerGetQueueJobs$Response(
    params: {
      queue: string;
      start?: number;
      end?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AdminApi.AdminControllerGetQueueJobsPath, 'get');
    if (params) {
      rb.path('queue', params.queue, {});
      rb.query('start', params.start, {});
      rb.query('end', params.end, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminControllerGetQueueJobs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminControllerGetQueueJobs(
    params: {
      queue: string;
      start?: number;
      end?: number;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.adminControllerGetQueueJobs$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `adminControllerGetMailQueues()` */
  static readonly AdminControllerGetMailQueuesPath = '/api/admin/mail-queues';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminControllerGetMailQueues()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminControllerGetMailQueues$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AdminApi.AdminControllerGetMailQueuesPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminControllerGetMailQueues$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminControllerGetMailQueues(
    params?: {
    },
    context?: HttpContext
  ): Observable<void> {
    return this.adminControllerGetMailQueues$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `adminControllerDeleteUsers()` */
  static readonly AdminControllerDeleteUsersPath = '/api/admin/users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminControllerDeleteUsers()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminControllerDeleteUsers$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AdminApi.AdminControllerDeleteUsersPath, 'delete');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminControllerDeleteUsers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminControllerDeleteUsers(
    params?: {
    },
    context?: HttpContext
  ): Observable<void> {
    return this.adminControllerDeleteUsers$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `adminControllerMigrateUp()` */
  static readonly AdminControllerMigrateUpPath = '/api/admin/migrate/{name}/up';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminControllerMigrateUp()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminControllerMigrateUp$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AdminApi.AdminControllerMigrateUpPath, 'post');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminControllerMigrateUp$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminControllerMigrateUp(
    params?: {
    },
    context?: HttpContext
  ): Observable<void> {
    return this.adminControllerMigrateUp$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `adminControllerMigrateDown()` */
  static readonly AdminControllerMigrateDownPath = '/api/admin/migrate/{name}/down';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminControllerMigrateDown()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminControllerMigrateDown$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AdminApi.AdminControllerMigrateDownPath, 'post');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminControllerMigrateDown$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminControllerMigrateDown(
    params?: {
    },
    context?: HttpContext
  ): Observable<void> {
    return this.adminControllerMigrateDown$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
