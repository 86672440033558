/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AppendParticipantRequestDto } from '../models/append-participant-request-dto';
import { GetParticipantResponseDto } from '../models/get-participant-response-dto';
import { GetPodcastEpisodesResponse } from '../models/get-podcast-episodes-response';
import { ListWebsiteEpisodesResponseDto } from '../models/list-website-episodes-response-dto';
import { PodcastEpisodeDto } from '../models/podcast-episode-dto';
import { PodcastType } from '../models/podcast-type';
import { RestrictedPodcastEpisodeDto } from '../models/restricted-podcast-episode-dto';
import { TagsAndCategoriesDto } from '../models/tags-and-categories-dto';
import { UpdateEpisodeDto } from '../models/update-episode-dto';

@Injectable({ providedIn: 'root' })
export class EpisodeApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `episodeControllerGetEpisode()` */
  static readonly EpisodeControllerGetEpisodePath = '/api/episode/slug/{slug}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `episodeControllerGetEpisode()` instead.
   *
   * This method doesn't expect any request body.
   */
  episodeControllerGetEpisode$Response(
    params: {
      slug: string;
      podcastSlug?: string;
      podcastType?: PodcastType;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<(PodcastEpisodeDto | RestrictedPodcastEpisodeDto)>> {
    const rb = new RequestBuilder(this.rootUrl, EpisodeApi.EpisodeControllerGetEpisodePath, 'get');
    if (params) {
      rb.path('slug', params.slug, {});
      rb.query('podcastSlug', params.podcastSlug, {});
      rb.query('podcastType', params.podcastType, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<(PodcastEpisodeDto | RestrictedPodcastEpisodeDto)>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `episodeControllerGetEpisode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  episodeControllerGetEpisode(
    params: {
      slug: string;
      podcastSlug?: string;
      podcastType?: PodcastType;
    },
    context?: HttpContext
  ): Observable<(PodcastEpisodeDto | RestrictedPodcastEpisodeDto)> {
    return this.episodeControllerGetEpisode$Response(params, context).pipe(
      map((r: StrictHttpResponse<(PodcastEpisodeDto | RestrictedPodcastEpisodeDto)>): (PodcastEpisodeDto | RestrictedPodcastEpisodeDto) => r.body)
    );
  }

  /** Path part for operation `episodeControllerGetEpisodesForWebsite()` */
  static readonly EpisodeControllerGetEpisodesForWebsitePath = '/api/episode';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `episodeControllerGetEpisodesForWebsite()` instead.
   *
   * This method doesn't expect any request body.
   */
  episodeControllerGetEpisodesForWebsite$Response(
    params?: {
      participantId?: string;
      category?: string;
      tag?: string;
      season?: string;
      title?: string;
      slug?: string;
      limit?: number;
      offset?: number;
      sortDirection?: 'asc' | 'desc';
      year?: number;
      archived?: boolean;
      featured?: boolean;
      podcastType?: PodcastType;
      podcastId?: Array<string>;
      podcastSlug?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ListWebsiteEpisodesResponseDto>> {
    const rb = new RequestBuilder(this.rootUrl, EpisodeApi.EpisodeControllerGetEpisodesForWebsitePath, 'get');
    if (params) {
      rb.query('participantId', params.participantId, {});
      rb.query('category', params.category, {});
      rb.query('tag', params.tag, {});
      rb.query('season', params.season, {});
      rb.query('title', params.title, {});
      rb.query('slug', params.slug, {});
      rb.query('limit', params.limit, {});
      rb.query('offset', params.offset, {});
      rb.query('sortDirection', params.sortDirection, {});
      rb.query('year', params.year, {});
      rb.query('archived', params.archived, {});
      rb.query('featured', params.featured, {});
      rb.query('podcastType', params.podcastType, {});
      rb.query('podcastId', params.podcastId, {});
      rb.query('podcastSlug', params.podcastSlug, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListWebsiteEpisodesResponseDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `episodeControllerGetEpisodesForWebsite$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  episodeControllerGetEpisodesForWebsite(
    params?: {
      participantId?: string;
      category?: string;
      tag?: string;
      season?: string;
      title?: string;
      slug?: string;
      limit?: number;
      offset?: number;
      sortDirection?: 'asc' | 'desc';
      year?: number;
      archived?: boolean;
      featured?: boolean;
      podcastType?: PodcastType;
      podcastId?: Array<string>;
      podcastSlug?: string;
    },
    context?: HttpContext
  ): Observable<ListWebsiteEpisodesResponseDto> {
    return this.episodeControllerGetEpisodesForWebsite$Response(params, context).pipe(
      map((r: StrictHttpResponse<ListWebsiteEpisodesResponseDto>): ListWebsiteEpisodesResponseDto => r.body)
    );
  }

  /** Path part for operation `episodeControllerGetWebsiteCategories()` */
  static readonly EpisodeControllerGetWebsiteCategoriesPath = '/api/episode/categories';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `episodeControllerGetWebsiteCategories()` instead.
   *
   * This method doesn't expect any request body.
   */
  episodeControllerGetWebsiteCategories$Response(
    params?: {
      podcastId?: string;
      podcastSlug?: string;
      podcastType?: PodcastType;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<string>>> {
    const rb = new RequestBuilder(this.rootUrl, EpisodeApi.EpisodeControllerGetWebsiteCategoriesPath, 'get');
    if (params) {
      rb.query('podcastId', params.podcastId, {});
      rb.query('podcastSlug', params.podcastSlug, {});
      rb.query('podcastType', params.podcastType, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `episodeControllerGetWebsiteCategories$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  episodeControllerGetWebsiteCategories(
    params?: {
      podcastId?: string;
      podcastSlug?: string;
      podcastType?: PodcastType;
    },
    context?: HttpContext
  ): Observable<Array<string>> {
    return this.episodeControllerGetWebsiteCategories$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `episodeControllerGetEpisodeById()` */
  static readonly EpisodeControllerGetEpisodeByIdPath = '/api/episode/{episodeId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `episodeControllerGetEpisodeById()` instead.
   *
   * This method doesn't expect any request body.
   */
  episodeControllerGetEpisodeById$Response(
    params: {
      episodeId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PodcastEpisodeDto>> {
    const rb = new RequestBuilder(this.rootUrl, EpisodeApi.EpisodeControllerGetEpisodeByIdPath, 'get');
    if (params) {
      rb.path('episodeId', params.episodeId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PodcastEpisodeDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `episodeControllerGetEpisodeById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  episodeControllerGetEpisodeById(
    params: {
      episodeId: string;
    },
    context?: HttpContext
  ): Observable<PodcastEpisodeDto> {
    return this.episodeControllerGetEpisodeById$Response(params, context).pipe(
      map((r: StrictHttpResponse<PodcastEpisodeDto>): PodcastEpisodeDto => r.body)
    );
  }

  /** Path part for operation `episodeControllerDeleteEpisode()` */
  static readonly EpisodeControllerDeleteEpisodePath = '/api/episode/{episodeId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `episodeControllerDeleteEpisode()` instead.
   *
   * This method doesn't expect any request body.
   */
  episodeControllerDeleteEpisode$Response(
    params: {
      episodeId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, EpisodeApi.EpisodeControllerDeleteEpisodePath, 'delete');
    if (params) {
      rb.path('episodeId', params.episodeId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `episodeControllerDeleteEpisode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  episodeControllerDeleteEpisode(
    params: {
      episodeId: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.episodeControllerDeleteEpisode$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `episodeControllerEditEpisode()` */
  static readonly EpisodeControllerEditEpisodePath = '/api/episode/{episodeId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `episodeControllerEditEpisode()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  episodeControllerEditEpisode$Response(
    params: {
      episodeId: string;
      body: UpdateEpisodeDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, EpisodeApi.EpisodeControllerEditEpisodePath, 'patch');
    if (params) {
      rb.path('episodeId', params.episodeId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `episodeControllerEditEpisode$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  episodeControllerEditEpisode(
    params: {
      episodeId: string;
      body: UpdateEpisodeDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.episodeControllerEditEpisode$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `episodeControllerGetPodcastTagsAndCategories()` */
  static readonly EpisodeControllerGetPodcastTagsAndCategoriesPath = '/api/episode/{id}/podcast-tags-categories';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `episodeControllerGetPodcastTagsAndCategories()` instead.
   *
   * This method doesn't expect any request body.
   */
  episodeControllerGetPodcastTagsAndCategories$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<TagsAndCategoriesDto>> {
    const rb = new RequestBuilder(this.rootUrl, EpisodeApi.EpisodeControllerGetPodcastTagsAndCategoriesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TagsAndCategoriesDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `episodeControllerGetPodcastTagsAndCategories$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  episodeControllerGetPodcastTagsAndCategories(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<TagsAndCategoriesDto> {
    return this.episodeControllerGetPodcastTagsAndCategories$Response(params, context).pipe(
      map((r: StrictHttpResponse<TagsAndCategoriesDto>): TagsAndCategoriesDto => r.body)
    );
  }

  /** Path part for operation `episodeControllerGetEpisodes()` */
  static readonly EpisodeControllerGetEpisodesPath = '/api/episode/podcast/{podcastId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `episodeControllerGetEpisodes()` instead.
   *
   * This method doesn't expect any request body.
   */
  episodeControllerGetEpisodes$Response(
    params: {
      podcastId: string;
      participantId?: string;
      category?: string;
      tag?: string;
      season?: string;
      title?: string;
      slug?: string;
      limit?: number;
      offset?: number;
      sortDirection?: 'asc' | 'desc';
      year?: number;
      archived?: boolean;
      featured?: boolean;
      podcastType?: PodcastType;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<GetPodcastEpisodesResponse>> {
    const rb = new RequestBuilder(this.rootUrl, EpisodeApi.EpisodeControllerGetEpisodesPath, 'get');
    if (params) {
      rb.path('podcastId', params.podcastId, {});
      rb.query('participantId', params.participantId, {});
      rb.query('category', params.category, {});
      rb.query('tag', params.tag, {});
      rb.query('season', params.season, {});
      rb.query('title', params.title, {});
      rb.query('slug', params.slug, {});
      rb.query('limit', params.limit, {});
      rb.query('offset', params.offset, {});
      rb.query('sortDirection', params.sortDirection, {});
      rb.query('year', params.year, {});
      rb.query('archived', params.archived, {});
      rb.query('featured', params.featured, {});
      rb.query('podcastType', params.podcastType, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetPodcastEpisodesResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `episodeControllerGetEpisodes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  episodeControllerGetEpisodes(
    params: {
      podcastId: string;
      participantId?: string;
      category?: string;
      tag?: string;
      season?: string;
      title?: string;
      slug?: string;
      limit?: number;
      offset?: number;
      sortDirection?: 'asc' | 'desc';
      year?: number;
      archived?: boolean;
      featured?: boolean;
      podcastType?: PodcastType;
    },
    context?: HttpContext
  ): Observable<GetPodcastEpisodesResponse> {
    return this.episodeControllerGetEpisodes$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetPodcastEpisodesResponse>): GetPodcastEpisodesResponse => r.body)
    );
  }

  /** Path part for operation `episodeControllerArchiveEpisode()` */
  static readonly EpisodeControllerArchiveEpisodePath = '/api/episode/{episodeId}/archive';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `episodeControllerArchiveEpisode()` instead.
   *
   * This method doesn't expect any request body.
   */
  episodeControllerArchiveEpisode$Response(
    params: {
      episodeId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, EpisodeApi.EpisodeControllerArchiveEpisodePath, 'patch');
    if (params) {
      rb.path('episodeId', params.episodeId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `episodeControllerArchiveEpisode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  episodeControllerArchiveEpisode(
    params: {
      episodeId: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.episodeControllerArchiveEpisode$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `episodeControllerRestoreArchivedEpisode()` */
  static readonly EpisodeControllerRestoreArchivedEpisodePath = '/api/episode/{episodeId}/restore';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `episodeControllerRestoreArchivedEpisode()` instead.
   *
   * This method doesn't expect any request body.
   */
  episodeControllerRestoreArchivedEpisode$Response(
    params: {
      episodeId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, EpisodeApi.EpisodeControllerRestoreArchivedEpisodePath, 'post');
    if (params) {
      rb.path('episodeId', params.episodeId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `episodeControllerRestoreArchivedEpisode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  episodeControllerRestoreArchivedEpisode(
    params: {
      episodeId: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.episodeControllerRestoreArchivedEpisode$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `episodeControllerListParticipants()` */
  static readonly EpisodeControllerListParticipantsPath = '/api/episode/{siteId}/{id}/{podcastId}/participant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `episodeControllerListParticipants()` instead.
   *
   * This method doesn't expect any request body.
   */
  episodeControllerListParticipants$Response(
    params: {
      id: string;
      podcastId: string;
      siteId: string;
      slug?: string;
      status?: 'APPROVED' | 'PENDING' | 'DECLINED';
      role?: string;
      name?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<GetParticipantResponseDto>>> {
    const rb = new RequestBuilder(this.rootUrl, EpisodeApi.EpisodeControllerListParticipantsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('podcastId', params.podcastId, {});
      rb.path('siteId', params.siteId, {});
      rb.query('slug', params.slug, {});
      rb.query('status', params.status, {});
      rb.query('role', params.role, {});
      rb.query('name', params.name, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GetParticipantResponseDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `episodeControllerListParticipants$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  episodeControllerListParticipants(
    params: {
      id: string;
      podcastId: string;
      siteId: string;
      slug?: string;
      status?: 'APPROVED' | 'PENDING' | 'DECLINED';
      role?: string;
      name?: string;
    },
    context?: HttpContext
  ): Observable<Array<GetParticipantResponseDto>> {
    return this.episodeControllerListParticipants$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GetParticipantResponseDto>>): Array<GetParticipantResponseDto> => r.body)
    );
  }

  /** Path part for operation `episodeControllerAppendParticipant()` */
  static readonly EpisodeControllerAppendParticipantPath = '/api/episode/{id}/participant/{participantId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `episodeControllerAppendParticipant()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  episodeControllerAppendParticipant$Response(
    params: {
      id: string;
      participantId: string;
      body: AppendParticipantRequestDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, EpisodeApi.EpisodeControllerAppendParticipantPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('participantId', params.participantId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `episodeControllerAppendParticipant$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  episodeControllerAppendParticipant(
    params: {
      id: string;
      participantId: string;
      body: AppendParticipantRequestDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.episodeControllerAppendParticipant$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `episodeControllerRemoveParticipant()` */
  static readonly EpisodeControllerRemoveParticipantPath = '/api/episode/{id}/participant/{participantId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `episodeControllerRemoveParticipant()` instead.
   *
   * This method doesn't expect any request body.
   */
  episodeControllerRemoveParticipant$Response(
    params: {
      id: string;
      participantId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, EpisodeApi.EpisodeControllerRemoveParticipantPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('participantId', params.participantId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `episodeControllerRemoveParticipant$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  episodeControllerRemoveParticipant(
    params: {
      id: string;
      participantId: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.episodeControllerRemoveParticipant$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
