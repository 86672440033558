<div class="error-page" [ngStyle]="{'background-image': 'url(assets/signup_error.svg)'}">
  <div class="error-container">
    <div>
      <div class="text-center">
        <img class="logo w-32 mx-auto cursor-pointer mb-8" routerLink="/" src="assets/podcastpage_logo_small.svg" />
      </div>
      <h1 class="mb-12">{{route.snapshot.params.errorCode}} Error</h1>
      <p class="mb-6 text-5xl">¯\_(ツ)_/¯</p>
      <p class="error-message mb-12">Sorry! An error has occurred. Please try again.</p>
      <button class="btn" (click)="goBack()">Go back</button>
    </div>
  </div>
</div>