<div class="reset-page" [ngStyle]="{'background-image': 'url(assets/login_shape.svg)'}">
  <div>
    <img class="logo text-center" src="assets/podcastpage_wide_logo_white.svg" />
    <div class="reset-container">
      <div>
        <h1 class="mb-16 text-5xl">Reset Password</h1>
        <form [formGroup]="form" (ngSubmit)="formSubmit(form)">

          <div class="mb-8 relative">
            <input formControlName="password" placeholder=" " type="password" id="password" class="default" />
            <label for="password">New Password</label>
            <p class="error-label" *ngIf="password.invalid && (password.dirty || password.touched)">
              <ng-container *ngIf="password.errors.required">Password is required.</ng-container>
              <ng-container *ngIf="password.errors.minlength; let error;">Password must contain at least
                {{error.requiredLength}} characters.</ng-container>
            </p>
          </div>

          <div class="mb-8 relative">
            <input formControlName="confirmPassword" placeholder=" " type="password" id="repeat-password" class="default" />
            <label for="password">Repeat Password</label>
            <p class="error-label"
              *ngIf="confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched)">
              Passwords do not match
            </p>
          </div>

          <div>
            <button class="btn block w-full submit">Reset Password</button>
          </div>

        </form>
      </div>
    </div>
  </div>
</div>

<ng-progress #loader id="reset-password-loader"></ng-progress>