/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { LoginResponseDto } from '../models/login-response-dto';

@Injectable({ providedIn: 'root' })
export class AuthApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `authControllerLogOut()` */
  static readonly AuthControllerLogOutPath = '/api/auth/logout';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerLogOut()` instead.
   *
   * This method doesn't expect any request body.
   */
  authControllerLogOut$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AuthApi.AuthControllerLogOutPath, 'post');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authControllerLogOut$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authControllerLogOut(
    params?: {
    },
    context?: HttpContext
  ): Observable<void> {
    return this.authControllerLogOut$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `authControllerRefreshToken()` */
  static readonly AuthControllerRefreshTokenPath = '/api/auth/refresh';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerRefreshToken()` instead.
   *
   * This method doesn't expect any request body.
   */
  authControllerRefreshToken$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<LoginResponseDto>> {
    const rb = new RequestBuilder(this.rootUrl, AuthApi.AuthControllerRefreshTokenPath, 'post');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoginResponseDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authControllerRefreshToken$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authControllerRefreshToken(
    params?: {
    },
    context?: HttpContext
  ): Observable<LoginResponseDto> {
    return this.authControllerRefreshToken$Response(params, context).pipe(
      map((r: StrictHttpResponse<LoginResponseDto>): LoginResponseDto => r.body)
    );
  }

}
