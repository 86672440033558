import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { FormValidatorService } from '../../shared/services/form-validator/form-validator.service';
import { first, finalize } from 'rxjs/operators';
import { UserApi } from 'src/app/api/services';
import { NgProgressComponent } from 'ngx-progressbar';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  @ViewChild('loader') loader: NgProgressComponent;
  form: UntypedFormGroup;
  emailSent = false;

  constructor(
    private fb: UntypedFormBuilder,
    private formValidator: FormValidatorService,
    private userApi: UserApi) {
    this.form = fb.group({
      email: ['', [Validators.required, Validators.email]],
    })
  }

  ngOnInit(): void {
  }

  formSubmit(form: UntypedFormGroup) {
    if (form.valid) {
      this.sendEmail(form.get('email').value?.toLowerCase())
    } else {
      this.formValidator.validateAllFormFields(form);
    }
  }

  sendEmail(email: string) {
    this.loader.start();
    this.userApi.userControllerForgotPassword({ body: { email } })
    .pipe(
      first(),
      finalize(() => this.loader.complete())
    ).subscribe(res => this.emailSent = true);
  }

  get email() {
    return this.form.get('email');
  }

}
