/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { CreateSubscriptionPlanCouponDto } from '../models/create-subscription-plan-coupon-dto';
import { CreateSubscriptionPlanDto } from '../models/create-subscription-plan-dto';
import { SubscriptionPlanAddonDto } from '../models/subscription-plan-addon-dto';
import { SubscriptionPlanDto } from '../models/subscription-plan-dto';
import { UpdateSubscriptionPlanAddonDto } from '../models/update-subscription-plan-addon-dto';

@Injectable({ providedIn: 'root' })
export class SubscriptionPlanApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `subscriptionPlanControllerGetPlans()` */
  static readonly SubscriptionPlanControllerGetPlansPath = '/api/subscription-plan';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscriptionPlanControllerGetPlans()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionPlanControllerGetPlans$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<SubscriptionPlanDto>>> {
    const rb = new RequestBuilder(this.rootUrl, SubscriptionPlanApi.SubscriptionPlanControllerGetPlansPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SubscriptionPlanDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subscriptionPlanControllerGetPlans$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionPlanControllerGetPlans(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<SubscriptionPlanDto>> {
    return this.subscriptionPlanControllerGetPlans$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SubscriptionPlanDto>>): Array<SubscriptionPlanDto> => r.body)
    );
  }

  /** Path part for operation `subscriptionPlanControllerCreatePlan()` */
  static readonly SubscriptionPlanControllerCreatePlanPath = '/api/subscription-plan';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscriptionPlanControllerCreatePlan()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  subscriptionPlanControllerCreatePlan$Response(
    params: {
      body: CreateSubscriptionPlanDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, SubscriptionPlanApi.SubscriptionPlanControllerCreatePlanPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subscriptionPlanControllerCreatePlan$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  subscriptionPlanControllerCreatePlan(
    params: {
      body: CreateSubscriptionPlanDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.subscriptionPlanControllerCreatePlan$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `subscriptionPlanControllerCreateDefaultPlans()` */
  static readonly SubscriptionPlanControllerCreateDefaultPlansPath = '/api/subscription-plan/create-default-plans';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscriptionPlanControllerCreateDefaultPlans()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionPlanControllerCreateDefaultPlans$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<{
}>>> {
    const rb = new RequestBuilder(this.rootUrl, SubscriptionPlanApi.SubscriptionPlanControllerCreateDefaultPlansPath, 'post');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<{
        }>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subscriptionPlanControllerCreateDefaultPlans$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionPlanControllerCreateDefaultPlans(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<{
}>> {
    return this.subscriptionPlanControllerCreateDefaultPlans$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<{
}>>): Array<{
}> => r.body)
    );
  }

  /** Path part for operation `subscriptionPlanControllerCreateCoupon()` */
  static readonly SubscriptionPlanControllerCreateCouponPath = '/api/subscription-plan/coupon';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscriptionPlanControllerCreateCoupon()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  subscriptionPlanControllerCreateCoupon$Response(
    params: {
      body: CreateSubscriptionPlanCouponDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
}>> {
    const rb = new RequestBuilder(this.rootUrl, SubscriptionPlanApi.SubscriptionPlanControllerCreateCouponPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subscriptionPlanControllerCreateCoupon$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  subscriptionPlanControllerCreateCoupon(
    params: {
      body: CreateSubscriptionPlanCouponDto
    },
    context?: HttpContext
  ): Observable<{
}> {
    return this.subscriptionPlanControllerCreateCoupon$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `subscriptionPlanControllerListAddons()` */
  static readonly SubscriptionPlanControllerListAddonsPath = '/api/subscription-plan/addon';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscriptionPlanControllerListAddons()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionPlanControllerListAddons$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<SubscriptionPlanAddonDto>>> {
    const rb = new RequestBuilder(this.rootUrl, SubscriptionPlanApi.SubscriptionPlanControllerListAddonsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SubscriptionPlanAddonDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subscriptionPlanControllerListAddons$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionPlanControllerListAddons(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<SubscriptionPlanAddonDto>> {
    return this.subscriptionPlanControllerListAddons$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SubscriptionPlanAddonDto>>): Array<SubscriptionPlanAddonDto> => r.body)
    );
  }

  /** Path part for operation `subscriptionPlanControllerCreateAddon()` */
  static readonly SubscriptionPlanControllerCreateAddonPath = '/api/subscription-plan/addon';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscriptionPlanControllerCreateAddon()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  subscriptionPlanControllerCreateAddon$Response(
    params: {
      body: Array<string>
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<{
}>>> {
    const rb = new RequestBuilder(this.rootUrl, SubscriptionPlanApi.SubscriptionPlanControllerCreateAddonPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<{
        }>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subscriptionPlanControllerCreateAddon$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  subscriptionPlanControllerCreateAddon(
    params: {
      body: Array<string>
    },
    context?: HttpContext
  ): Observable<Array<{
}>> {
    return this.subscriptionPlanControllerCreateAddon$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<{
}>>): Array<{
}> => r.body)
    );
  }

  /** Path part for operation `subscriptionPlanControllerUpdateAddon()` */
  static readonly SubscriptionPlanControllerUpdateAddonPath = '/api/subscription-plan/addon/{addonId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscriptionPlanControllerUpdateAddon()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  subscriptionPlanControllerUpdateAddon$Response(
    params: {
      addonId: string;
      body: UpdateSubscriptionPlanAddonDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
}>> {
    const rb = new RequestBuilder(this.rootUrl, SubscriptionPlanApi.SubscriptionPlanControllerUpdateAddonPath, 'put');
    if (params) {
      rb.path('addonId', params.addonId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subscriptionPlanControllerUpdateAddon$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  subscriptionPlanControllerUpdateAddon(
    params: {
      addonId: string;
      body: UpdateSubscriptionPlanAddonDto
    },
    context?: HttpContext
  ): Observable<{
}> {
    return this.subscriptionPlanControllerUpdateAddon$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `subscriptionPlanControllerDeleteAddon()` */
  static readonly SubscriptionPlanControllerDeleteAddonPath = '/api/subscription-plan/addon/{addonId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscriptionPlanControllerDeleteAddon()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionPlanControllerDeleteAddon$Response(
    params: {
      addonId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, SubscriptionPlanApi.SubscriptionPlanControllerDeleteAddonPath, 'delete');
    if (params) {
      rb.path('addonId', params.addonId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subscriptionPlanControllerDeleteAddon$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionPlanControllerDeleteAddon(
    params: {
      addonId: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.subscriptionPlanControllerDeleteAddon$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
