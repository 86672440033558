/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { GetOAuthsResponseDto } from '../models/get-o-auths-response-dto';
import { OAuthRevokeTokenRequestDto } from '../models/o-auth-revoke-token-request-dto';
import { OauthCallbackDto } from '../models/oauth-callback-dto';
import { OauthProviderEnum } from '../models/oauth-provider-enum';
import { OauthRequestTokenResponseDto } from '../models/oauth-request-token-response-dto';

@Injectable({ providedIn: 'root' })
export class OauthApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `oauthControllerGetRequestToken()` */
  static readonly OauthControllerGetRequestTokenPath = '/api/oauth/token';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `oauthControllerGetRequestToken()` instead.
   *
   * This method doesn't expect any request body.
   */
  oauthControllerGetRequestToken$Response(
    params: {
      siteId: string;
      provider: OauthProviderEnum;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<OauthRequestTokenResponseDto>> {
    const rb = new RequestBuilder(this.rootUrl, OauthApi.OauthControllerGetRequestTokenPath, 'get');
    if (params) {
      rb.query('siteId', params.siteId, {});
      rb.query('provider', params.provider, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OauthRequestTokenResponseDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `oauthControllerGetRequestToken$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  oauthControllerGetRequestToken(
    params: {
      siteId: string;
      provider: OauthProviderEnum;
    },
    context?: HttpContext
  ): Observable<OauthRequestTokenResponseDto> {
    return this.oauthControllerGetRequestToken$Response(params, context).pipe(
      map((r: StrictHttpResponse<OauthRequestTokenResponseDto>): OauthRequestTokenResponseDto => r.body)
    );
  }

  /** Path part for operation `oauthControllerAuthorize()` */
  static readonly OauthControllerAuthorizePath = '/api/oauth/authorize';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `oauthControllerAuthorize()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  oauthControllerAuthorize$Response(
    params: {
      body: OauthCallbackDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, OauthApi.OauthControllerAuthorizePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `oauthControllerAuthorize$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  oauthControllerAuthorize(
    params: {
      body: OauthCallbackDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.oauthControllerAuthorize$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `oauthControllerRevokeAuth()` */
  static readonly OauthControllerRevokeAuthPath = '/api/oauth/revoke-auth';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `oauthControllerRevokeAuth()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  oauthControllerRevokeAuth$Response(
    params: {
      body: OAuthRevokeTokenRequestDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, OauthApi.OauthControllerRevokeAuthPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `oauthControllerRevokeAuth$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  oauthControllerRevokeAuth(
    params: {
      body: OAuthRevokeTokenRequestDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.oauthControllerRevokeAuth$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `oauthControllerGetAuthentications()` */
  static readonly OauthControllerGetAuthenticationsPath = '/api/oauth/{siteId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `oauthControllerGetAuthentications()` instead.
   *
   * This method doesn't expect any request body.
   */
  oauthControllerGetAuthentications$Response(
    params: {
      siteId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<GetOAuthsResponseDto>>> {
    const rb = new RequestBuilder(this.rootUrl, OauthApi.OauthControllerGetAuthenticationsPath, 'get');
    if (params) {
      rb.path('siteId', params.siteId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GetOAuthsResponseDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `oauthControllerGetAuthentications$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  oauthControllerGetAuthentications(
    params: {
      siteId: string;
    },
    context?: HttpContext
  ): Observable<Array<GetOAuthsResponseDto>> {
    return this.oauthControllerGetAuthentications$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GetOAuthsResponseDto>>): Array<GetOAuthsResponseDto> => r.body)
    );
  }

}
