import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { UserApi } from '../../../api/services';
import { UserDto , CreateUserWithPasswordDto} from '../../../api/models';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private user: BehaviorSubject<UserDto> = new BehaviorSubject(null);

  user$ = this.user.asObservable();

  constructor(private userApi: UserApi) { }

  fetchUser() {
    return this.userApi.userControllerGetUser().pipe(
      first(),
      tap(user => {
        this.user.next(user)
      })
    )
  }

  createUserWithPassword(freeTrial: boolean, body: CreateUserWithPasswordDto) {
    return this.userApi.userControllerSignUpWithPassword({ body, freeTrial })
  }

  deleteUser() {
    return this.userApi.userControllerDeleteAccount()
  }

  get userSnapshot() {
    return this.user.value;
  }

}
