/* tslint:disable */
/* eslint-disable */
export enum WebsiteResourceTypeDto {
  Podcast = 'PODCAST',
  VideoChannel = 'VIDEO_CHANNEL',
  Course = 'COURSE',
  Episode = 'EPISODE',
  Page = 'PAGE',
  Blog = 'BLOG',
  BlogPost = 'BLOG_POST'
}
