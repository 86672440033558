import { WebsiteCollaboratorDto } from 'src/app/api/models';
import { WebsiteInfoDto } from '../../api/models/website-info-dto';

export interface SiteInfo {
  id: string;
  user: string;
  name: string;
  slug: string;
  url: string;
  active: boolean;
  pending: boolean;
  subdomain?: string;
  domain?: string;
  collaborators: WebsiteCollaboratorDto[];
  analytics: boolean;
}

export class SiteInfo {
  static fromApi(site: WebsiteInfoDto) {
    const instance = new SiteInfo();
    instance.id = site.id;
    instance.user = site.user;
    instance.name = site.name;
    instance.slug = site.slug;
    instance.url = site.url;
    instance.active = site.active;
    instance.pending = false;
    instance.subdomain = site.subdomain;
    instance.domain = site.customDomain;
    instance.collaborators = site.collaborators;
    instance.analytics = site.analytics;
    return instance;
  }
}
