/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AddSubscriptionAddonDto } from '../models/add-subscription-addon-dto';
import { CreateSubscriptionFromCouponDto } from '../models/create-subscription-from-coupon-dto';
import { SubscriptionAddonDto } from '../models/subscription-addon-dto';
import { SubscriptionPaymentDto } from '../models/subscription-payment-dto';
import { SwitchSubscriptionAddonPlanDto } from '../models/switch-subscription-addon-plan-dto';
import { UserSubscriptionDto } from '../models/user-subscription-dto';

@Injectable({ providedIn: 'root' })
export class SubscriptionApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `subscriptionControllerCreateSubscriptionFromCoupon()` */
  static readonly SubscriptionControllerCreateSubscriptionFromCouponPath = '/api/subscription/coupon/{couponId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscriptionControllerCreateSubscriptionFromCoupon()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  subscriptionControllerCreateSubscriptionFromCoupon$Response(
    params: {
      couponId: string;
      body: CreateSubscriptionFromCouponDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
}>> {
    const rb = new RequestBuilder(this.rootUrl, SubscriptionApi.SubscriptionControllerCreateSubscriptionFromCouponPath, 'post');
    if (params) {
      rb.path('couponId', params.couponId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subscriptionControllerCreateSubscriptionFromCoupon$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  subscriptionControllerCreateSubscriptionFromCoupon(
    params: {
      couponId: string;
      body: CreateSubscriptionFromCouponDto
    },
    context?: HttpContext
  ): Observable<{
}> {
    return this.subscriptionControllerCreateSubscriptionFromCoupon$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `subscriptionControllerCreateFreeTrialSubscription()` */
  static readonly SubscriptionControllerCreateFreeTrialSubscriptionPath = '/api/subscription/free-trial';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscriptionControllerCreateFreeTrialSubscription()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionControllerCreateFreeTrialSubscription$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, SubscriptionApi.SubscriptionControllerCreateFreeTrialSubscriptionPath, 'post');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subscriptionControllerCreateFreeTrialSubscription$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionControllerCreateFreeTrialSubscription(
    params?: {
    },
    context?: HttpContext
  ): Observable<void> {
    return this.subscriptionControllerCreateFreeTrialSubscription$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `subscriptionControllerGetSubscriptionPayments()` */
  static readonly SubscriptionControllerGetSubscriptionPaymentsPath = '/api/subscription/payments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscriptionControllerGetSubscriptionPayments()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionControllerGetSubscriptionPayments$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<SubscriptionPaymentDto>>> {
    const rb = new RequestBuilder(this.rootUrl, SubscriptionApi.SubscriptionControllerGetSubscriptionPaymentsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SubscriptionPaymentDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subscriptionControllerGetSubscriptionPayments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionControllerGetSubscriptionPayments(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<SubscriptionPaymentDto>> {
    return this.subscriptionControllerGetSubscriptionPayments$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SubscriptionPaymentDto>>): Array<SubscriptionPaymentDto> => r.body)
    );
  }

  /** Path part for operation `subscriptionControllerGetSubscription()` */
  static readonly SubscriptionControllerGetSubscriptionPath = '/api/subscription';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscriptionControllerGetSubscription()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionControllerGetSubscription$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<UserSubscriptionDto>> {
    const rb = new RequestBuilder(this.rootUrl, SubscriptionApi.SubscriptionControllerGetSubscriptionPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserSubscriptionDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subscriptionControllerGetSubscription$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionControllerGetSubscription(
    params?: {
    },
    context?: HttpContext
  ): Observable<UserSubscriptionDto> {
    return this.subscriptionControllerGetSubscription$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserSubscriptionDto>): UserSubscriptionDto => r.body)
    );
  }

  /** Path part for operation `subscriptionControllerCancelSubscription()` */
  static readonly SubscriptionControllerCancelSubscriptionPath = '/api/subscription/cancel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscriptionControllerCancelSubscription()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionControllerCancelSubscription$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, SubscriptionApi.SubscriptionControllerCancelSubscriptionPath, 'post');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subscriptionControllerCancelSubscription$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionControllerCancelSubscription(
    params?: {
    },
    context?: HttpContext
  ): Observable<void> {
    return this.subscriptionControllerCancelSubscription$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `subscriptionControllerChangeSubscriptionPlan()` */
  static readonly SubscriptionControllerChangeSubscriptionPlanPath = '/api/subscription/change-plan/{planId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscriptionControllerChangeSubscriptionPlan()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionControllerChangeSubscriptionPlan$Response(
    params: {
      planId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
}>> {
    const rb = new RequestBuilder(this.rootUrl, SubscriptionApi.SubscriptionControllerChangeSubscriptionPlanPath, 'post');
    if (params) {
      rb.path('planId', params.planId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subscriptionControllerChangeSubscriptionPlan$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionControllerChangeSubscriptionPlan(
    params: {
      planId: string;
    },
    context?: HttpContext
  ): Observable<{
}> {
    return this.subscriptionControllerChangeSubscriptionPlan$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `subscriptionControllerListAddons()` */
  static readonly SubscriptionControllerListAddonsPath = '/api/subscription/addon';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscriptionControllerListAddons()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionControllerListAddons$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<SubscriptionAddonDto>>> {
    const rb = new RequestBuilder(this.rootUrl, SubscriptionApi.SubscriptionControllerListAddonsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SubscriptionAddonDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subscriptionControllerListAddons$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionControllerListAddons(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<SubscriptionAddonDto>> {
    return this.subscriptionControllerListAddons$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SubscriptionAddonDto>>): Array<SubscriptionAddonDto> => r.body)
    );
  }

  /** Path part for operation `subscriptionControllerAddSubscriptionAddon()` */
  static readonly SubscriptionControllerAddSubscriptionAddonPath = '/api/subscription/addon';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscriptionControllerAddSubscriptionAddon()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  subscriptionControllerAddSubscriptionAddon$Response(
    params: {
      body: AddSubscriptionAddonDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, SubscriptionApi.SubscriptionControllerAddSubscriptionAddonPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subscriptionControllerAddSubscriptionAddon$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  subscriptionControllerAddSubscriptionAddon(
    params: {
      body: AddSubscriptionAddonDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.subscriptionControllerAddSubscriptionAddon$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `subscriptionControllerRemoveSubscriptionAddon()` */
  static readonly SubscriptionControllerRemoveSubscriptionAddonPath = '/api/subscription/addon/{addonId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscriptionControllerRemoveSubscriptionAddon()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionControllerRemoveSubscriptionAddon$Response(
    params: {
      addonId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, SubscriptionApi.SubscriptionControllerRemoveSubscriptionAddonPath, 'delete');
    if (params) {
      rb.path('addonId', params.addonId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subscriptionControllerRemoveSubscriptionAddon$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionControllerRemoveSubscriptionAddon(
    params: {
      addonId: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.subscriptionControllerRemoveSubscriptionAddon$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `subscriptionControllerSwitchAddon()` */
  static readonly SubscriptionControllerSwitchAddonPath = '/api/subscription/addon/{addonId}/switch-plan';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscriptionControllerSwitchAddon()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  subscriptionControllerSwitchAddon$Response(
    params: {
      addonId: string;
      body: SwitchSubscriptionAddonPlanDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, SubscriptionApi.SubscriptionControllerSwitchAddonPath, 'post');
    if (params) {
      rb.path('addonId', params.addonId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subscriptionControllerSwitchAddon$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  subscriptionControllerSwitchAddon(
    params: {
      addonId: string;
      body: SwitchSubscriptionAddonPlanDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.subscriptionControllerSwitchAddon$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `subscriptionControllerHelloHooks()` */
  static readonly SubscriptionControllerHelloHooksPath = '/api/subscription/hooks';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscriptionControllerHelloHooks()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionControllerHelloHooks$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
}>> {
    const rb = new RequestBuilder(this.rootUrl, SubscriptionApi.SubscriptionControllerHelloHooksPath, 'post');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subscriptionControllerHelloHooks$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionControllerHelloHooks(
    params?: {
    },
    context?: HttpContext
  ): Observable<{
}> {
    return this.subscriptionControllerHelloHooks$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

}
