import { Injectable } from '@angular/core';
import {BehaviorSubject, forkJoin, timer} from 'rxjs';
import {first, switchMap, map, tap, takeUntil, take, filter} from 'rxjs/operators';
import { SiteOverview } from '../../../shared/models/site-overview.model';
import { SiteInfo } from '../../../shared/models/site-info.model';
import { WebsiteApi } from '../../../api/services';
import { AddWebsiteCollaboratorRequestDto, CreateParticipantDto, UpdateParticipantDto } from '../../../api/models';
import {environment} from "../../../../environments/environment";
import { SubscriptionPlanFeaturesDto } from '../../../api/models';

@Injectable({
  providedIn: 'root'
})
export class SitesService {
  private sites: BehaviorSubject<SiteOverview[]> = new BehaviorSubject([]);
  private currentSiteInfo: BehaviorSubject<SiteInfo> = new BehaviorSubject(null);
  private currentSitePathUrl: BehaviorSubject<string> = new BehaviorSubject(null);
  private currentSitePathName: BehaviorSubject<string> = new BehaviorSubject(null);
  private currentSitePlanFeatures: BehaviorSubject<SubscriptionPlanFeaturesDto> = new BehaviorSubject(null);

  sites$ = this.sites.asObservable();
  currentSiteInfo$ = this.currentSiteInfo.asObservable();
  currentSitePathUrl$ = this.currentSitePathUrl.asObservable();
  currentSitePathName$ = this.currentSitePathName.asObservable();
  currentSitePlanFeatures$ = this.currentSitePlanFeatures.asObservable();

  constructor(private websiteApi: WebsiteApi) {}

  setSites(sites: SiteOverview[]) {
    this.sites.next(sites);
  }

  fetchAllSites() {
    return forkJoin(
      this.fetchSites(),
      this.fetchCollaboratorSites()
    ).pipe(
      map(([a, b]) => a.concat(b)),
      tap(sites => {
        this.setSites(sites);
      })
    )
  }

  fetchSites() {
    return this.websiteApi.websiteControllerListWebsites().pipe(
      map(results => results.map(res => SiteOverview.fromApi(res))),
      first()
    )
  }

  fetchCollaboratorSites() {
    return this.websiteApi.websiteControllerListWebsites({ isCollaborator: true }).pipe(
      map(results => results.map(res => SiteOverview.fromApi(res))),
      first()
    )
  }

  fetchSiteInfo(params : { id?: string, slug?: string }) {
    return this.websiteApi.websiteControllerGetWebsiteInfo(params).pipe(
      map(site => SiteInfo.fromApi(site)),
      tap(site => this.setCurrentSiteInfo(site)),
      tap(site => this.fetchWebsitePlanFeatures(site.id).subscribe()),
      first()
    )
  }

  createWebsite(name: string) {
    return this.websiteApi.websiteControllerCreateWebsite({ body: { name }}).pipe(first())
  }

  updateSiteSubdomain(siteId, subdomain: string) {
    return this.websiteApi.websiteControllerUpdateSubdomain({
      siteId, body: {subdomain}
    }).pipe(first())
  }

  validateSiteCustomDomain(siteId, domain: string) {
    return this.websiteApi.websiteControllerValidateCustomDomain({
      siteId,
      domain,
    }).pipe(first());
  }
  
  updateSiteCustomDomain(siteId, domain: string) {
    return this.websiteApi.websiteControllerUpdateCustomDomain({
      siteId,
      domain
    }).pipe(first())
  }

  deleteSite(siteId: string) {
    return this.websiteApi.websiteControllerDeleteWebsite({ siteId }).pipe(first());
  }

  purgeCache(siteId: string) {
    return this.websiteApi.websiteControllerPurgeCache({ siteId });
  }

  private scheduledCachePurge(siteId: string, delay: number) {
    return this.websiteApi.websiteControllerScheduledCachePurge({ siteId, delay });
  }

  deleteSiteContent(siteId: string) {
    return forkJoin(
      this.websiteApi.websiteControllerDeleteWebsitePages({ siteId }),
      this.websiteApi.websiteControllerDeleteWebsitePosts({ siteId })
    )
  }

  fetchWebsitePlanFeatures(siteId: string) {
    return this.websiteApi.websiteControllerGetSubscriptionPlanFeatures({ siteId }).pipe(
      map(features => this.setCurrentSitePlanFeatures(features as SubscriptionPlanFeaturesDto)),
      first()
    )
  }

  fetchWebsiteCollaborators(siteId: string) {
    return this.websiteApi.websiteControllerListCollaborators({ siteId }).pipe(first())
  }

  inviteCollaborator(siteId: string, options: AddWebsiteCollaboratorRequestDto) {
    return this.websiteApi.websiteControllerInviteCollaborator({ siteId, body: options })
  }

  acceptCollaboratorInvite(siteId: string) {
    return this.websiteApi.websiteControllerAcceptCollaboratorInvitation({ siteId }).pipe(first())
  }

  revokeCollaboratorAccess(siteId: string, collaboratorId: string) {
    return this.websiteApi.websiteControllerRemoveCollaborator({ siteId, collaboratorId })
  }

  listParticipants(siteId: string, status?: ('APPROVED' | 'PENDING'), role?: any ) {
    return this.websiteApi.websiteControllerListParticipants({ siteId, status, role }).pipe(first())
  }

  listParticipantBySlug(siteId: string, slug: string) {
    return this.websiteApi.websiteControllerListParticipantBySlug({ siteId, slug }).pipe(first())
  }

  createParticipant(siteId: string, body: CreateParticipantDto) {
    return this.websiteApi.websiteControllerCreateParticipant({ siteId, body }).pipe(first())
  }

  deleteParticipant(siteId: string, participantId: string) {
    return this.websiteApi.websiteControllerDeleteParticipant({ siteId, participantId }).pipe(first())
  }
  updateParticipant(siteId: string, participantId: string, body: UpdateParticipantDto) {
    return this.websiteApi.websiteControllerUpdateParticipant({ siteId, participantId, body }).pipe(first())
  }

  setCurrentUrl(path: string, name: string) {
    this.currentSitePathUrl.next(path);
    this.currentSitePathName.next(name);
  }

  setCurrentSiteInfo(site: SiteInfo) {
    this.currentSiteInfo.next(site);
  }

  fetchIntegrations(siteId: string) {
    return this.websiteApi.websiteControllerListIntegrations({ siteId }).pipe(first())
  }

  setCurrentSitePlanFeatures(features: SubscriptionPlanFeaturesDto) {
    this.currentSitePlanFeatures.next(features);
  }

  handleScheduledEntries(siteId: string, timestamp: number) {
    const now = new Date().getTime();
    const delay = timestamp - now;
    if (timestamp > now) return this.scheduledCachePurge(siteId, delay).pipe(first()).subscribe();
    return;
  }

  get sitesSnapshot() {
    return this.sites.value;
  }

  get siteInfoSnapshot() {
    return this.currentSiteInfo.value;
  }

  get currentPlanFeaturesSnapshot() {
    return this.currentSitePlanFeatures.value;
  }

}