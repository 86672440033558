/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { UpdateWebsiteCurrencyDto } from '../models/update-website-currency-dto';
import { WebsiteContentTextSuggestionRequestBodyDto } from '../models/website-content-text-suggestion-request-body-dto';
import { WebsiteContentTextSuggestionResponseDto } from '../models/website-content-text-suggestion-response-dto';
import { WebsiteSettingsDto } from '../models/website-settings-dto';

@Injectable({ providedIn: 'root' })
export class WebsiteContentApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `websiteContentControllerSuggestText()` */
  static readonly WebsiteContentControllerSuggestTextPath = '/api/website/{websiteId}/content/text/generate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteContentControllerSuggestText()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  websiteContentControllerSuggestText$Response(
    params: {
      websiteId: string;
      body: WebsiteContentTextSuggestionRequestBodyDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<WebsiteContentTextSuggestionResponseDto>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteContentApi.WebsiteContentControllerSuggestTextPath, 'post');
    if (params) {
      rb.path('websiteId', params.websiteId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WebsiteContentTextSuggestionResponseDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteContentControllerSuggestText$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  websiteContentControllerSuggestText(
    params: {
      websiteId: string;
      body: WebsiteContentTextSuggestionRequestBodyDto
    },
    context?: HttpContext
  ): Observable<WebsiteContentTextSuggestionResponseDto> {
    return this.websiteContentControllerSuggestText$Response(params, context).pipe(
      map((r: StrictHttpResponse<WebsiteContentTextSuggestionResponseDto>): WebsiteContentTextSuggestionResponseDto => r.body)
    );
  }

  /** Path part for operation `websiteSettingsControllerGetSettings()` */
  static readonly WebsiteSettingsControllerGetSettingsPath = '/api/website/{websiteId}/settings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteSettingsControllerGetSettings()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteSettingsControllerGetSettings$Response(
    params: {
      websiteId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<WebsiteSettingsDto>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteContentApi.WebsiteSettingsControllerGetSettingsPath, 'get');
    if (params) {
      rb.path('websiteId', params.websiteId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WebsiteSettingsDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteSettingsControllerGetSettings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteSettingsControllerGetSettings(
    params: {
      websiteId: string;
    },
    context?: HttpContext
  ): Observable<WebsiteSettingsDto> {
    return this.websiteSettingsControllerGetSettings$Response(params, context).pipe(
      map((r: StrictHttpResponse<WebsiteSettingsDto>): WebsiteSettingsDto => r.body)
    );
  }

  /** Path part for operation `websiteSettingsControllerUpdateCurrency()` */
  static readonly WebsiteSettingsControllerUpdateCurrencyPath = '/api/website/{websiteId}/settings/currency';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteSettingsControllerUpdateCurrency()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  websiteSettingsControllerUpdateCurrency$Response(
    params: {
      websiteId: string;
      body: UpdateWebsiteCurrencyDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteContentApi.WebsiteSettingsControllerUpdateCurrencyPath, 'patch');
    if (params) {
      rb.path('websiteId', params.websiteId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteSettingsControllerUpdateCurrency$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  websiteSettingsControllerUpdateCurrency(
    params: {
      websiteId: string;
      body: UpdateWebsiteCurrencyDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.websiteSettingsControllerUpdateCurrency$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
