import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { UserService } from '../../services/user/user.service';
import { filter, finalize, first, take, takeUntil, tap } from 'rxjs/operators';
import { SubscriptionStatusDto, UserDto } from 'src/app/api/models';
import { Observable, timer } from 'rxjs';
import { SubscriptionService } from '../../services/subscription/subscription.service';

@Injectable({
  providedIn: 'root'
})
export class ProcessingResolver  {
  subscriptionStatus = SubscriptionStatusDto;
  user$: Observable<UserDto>
  constructor(
    private userService: UserService,
    private router: Router,
    private subscriptionService: SubscriptionService
  ) {
    this.user$ = this.userService.user$
  }

  resolve(route: ActivatedRouteSnapshot) {
    return timer(600, 950).pipe(
      tap(() => this.fetchUserData()),
      takeUntil(this.user$.pipe(filter(u => u?.subscription.status === 'active'))),
      take(20),
      finalize(() => {
        return this.router.navigate(['/thank-you'])
      })
    ).subscribe()
  }

  fetchUserData() {
    this.userService.fetchUser().pipe(first()).subscribe()
    this.subscriptionService.fetchSubscription().pipe(first()).subscribe()
  }

}