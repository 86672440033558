import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { FormValidatorService } from '../../shared/services/form-validator/form-validator.service';
import { first, catchError, finalize } from 'rxjs/operators';
import { throwError, EMPTY } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { NgProgressComponent } from 'ngx-progressbar';
import { UserApi } from 'src/app/api/services';
import { AuthService } from '../../auth/services/auth/auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  @ViewChild('loader') private loader: NgProgressComponent;
  token: string;
  form: UntypedFormGroup;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private notifier: ToastrService,
    private formValidator: FormValidatorService,
    private fb: UntypedFormBuilder,
    private userApi: UserApi,
    private authService: AuthService,
  ) {
    this.form = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(1000)]],
      confirmPassword: ['']
    }, { validator: MustMatch('password', 'confirmPassword') })
  }

  ngOnInit(): void {
    const token = this.route.snapshot.paramMap.get('token');
    if (!token) {
      this.router.navigateByUrl('/error');
    }
    this.token = token;
  }

  formSubmit(form: UntypedFormGroup) {
    if (form.valid) {
      this.resetPassword(form.get('password').value)
    } else {
      this.formValidator.validateAllFormFields(form);
    }
  }

  resetPassword(password: string) {
    this.loader.start();
    this.userApi.userControllerResetPassword({ body: { password, token: this.token } }).pipe(
      first(),
      catchError(e => {
        this.notifier.error(`Failed to update password`)
        return EMPTY;
      }),
      finalize(() => this.loader.complete())
    ).subscribe(({ accessToken }) => {
      this.authService.setAccessToken(accessToken)
      this.notifier.success(`Password updated`)
      this.router.navigate(["../login"]);
    });
  }

  private checkPasswords(form: UntypedFormGroup) { // here we have the 'passwords' group
    let pass = form.get('password').value;
    let confirmPass = form.get('confirmPassword').value;

    return pass === confirmPass ? null : { notSame: true }
  }

  get password() {
    return this.form.get('password')
  }

  get confirmPassword() {
    return this.form.get('confirmPassword')
  }

}

function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: UntypedFormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  }
}
