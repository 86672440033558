/// <reference types="google.accounts" />
import { Injectable, NgZone } from '@angular/core';
import {environment} from "../../../../environments/environment";
import TokenResponse = google.accounts.oauth2.TokenResponse;
import TokenClient = google.accounts.oauth2.TokenClient;

@Injectable({
  providedIn: 'root'
})
export class GoogleAuthService {

  constructor(private zone: NgZone) { }

  async init(isSignUp: boolean): Promise<TokenResponse> {
    return new Promise((resolve, reject) => {

      /**
       * For login scenario email in scope is only needed.
       * For Sign up scenario, we need email and profile (to get basic public user information).
       */
      const scope = isSignUp ? 'email profile' : 'email';

      this.zone.run(() => {
        const tokenClient = google.accounts.oauth2.initTokenClient({
          client_id: environment.googleClientId,
          scope,
          prompt: '',
          callback: (tokenResponse) => {
            resolve(tokenResponse);
          }
        });

        tokenClient.requestAccessToken();
      });
    });
  }

}