/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { CreateReviewDto } from '../models/create-review-dto';
import { ListReviewsResponseDto } from '../models/list-reviews-response-dto';
import { PodcastType } from '../models/podcast-type';
import { ReviewDto } from '../models/review-dto';
import { UpdateReviewDto } from '../models/update-review-dto';

@Injectable({ providedIn: 'root' })
export class ReviewApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `reviewControllerSyncFeed()` */
  static readonly ReviewControllerSyncFeedPath = '/api/podcast/{podcastId}/review/sync';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reviewControllerSyncFeed()` instead.
   *
   * This method doesn't expect any request body.
   */
  reviewControllerSyncFeed$Response(
    params: {
      podcastId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, ReviewApi.ReviewControllerSyncFeedPath, 'post');
    if (params) {
      rb.path('podcastId', params.podcastId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reviewControllerSyncFeed$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reviewControllerSyncFeed(
    params: {
      podcastId: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.reviewControllerSyncFeed$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `reviewControllerGetReviewsForPodcast()` */
  static readonly ReviewControllerGetReviewsForPodcastPath = '/api/podcast/{podcastId}/review';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reviewControllerGetReviewsForPodcast()` instead.
   *
   * This method doesn't expect any request body.
   */
  reviewControllerGetReviewsForPodcast$Response(
    params: {
      podcastId: string;
      limit?: number;
      offset?: number;
      sortDirection?: 'asc' | 'desc';
      rating?: number;
      hidden?: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ListReviewsResponseDto>> {
    const rb = new RequestBuilder(this.rootUrl, ReviewApi.ReviewControllerGetReviewsForPodcastPath, 'get');
    if (params) {
      rb.path('podcastId', params.podcastId, {});
      rb.query('limit', params.limit, {});
      rb.query('offset', params.offset, {});
      rb.query('sortDirection', params.sortDirection, {});
      rb.query('rating', params.rating, {});
      rb.query('hidden', params.hidden, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListReviewsResponseDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reviewControllerGetReviewsForPodcast$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reviewControllerGetReviewsForPodcast(
    params: {
      podcastId: string;
      limit?: number;
      offset?: number;
      sortDirection?: 'asc' | 'desc';
      rating?: number;
      hidden?: boolean;
    },
    context?: HttpContext
  ): Observable<ListReviewsResponseDto> {
    return this.reviewControllerGetReviewsForPodcast$Response(params, context).pipe(
      map((r: StrictHttpResponse<ListReviewsResponseDto>): ListReviewsResponseDto => r.body)
    );
  }

  /** Path part for operation `reviewControllerUpdateReviewForPodcast()` */
  static readonly ReviewControllerUpdateReviewForPodcastPath = '/api/podcast/{podcastId}/review/{reviewId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reviewControllerUpdateReviewForPodcast()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  reviewControllerUpdateReviewForPodcast$Response(
    params: {
      podcastId: string;
      reviewId: string;
      body: UpdateReviewDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
}>> {
    const rb = new RequestBuilder(this.rootUrl, ReviewApi.ReviewControllerUpdateReviewForPodcastPath, 'patch');
    if (params) {
      rb.path('podcastId', params.podcastId, {});
      rb.path('reviewId', params.reviewId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reviewControllerUpdateReviewForPodcast$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  reviewControllerUpdateReviewForPodcast(
    params: {
      podcastId: string;
      reviewId: string;
      body: UpdateReviewDto
    },
    context?: HttpContext
  ): Observable<{
}> {
    return this.reviewControllerUpdateReviewForPodcast$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `websiteReviewControllerGetReviewsForWebsite()` */
  static readonly WebsiteReviewControllerGetReviewsForWebsitePath = '/api/website/podcast/review';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteReviewControllerGetReviewsForWebsite()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteReviewControllerGetReviewsForWebsite$Response(
    params?: {
      limit?: number;
      offset?: number;
      sortDirection?: 'asc' | 'desc';
      rating?: number;
      hidden?: boolean;
      podcastId?: Array<string>;
      podcastSlug?: string;
      podcastType?: PodcastType;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ListReviewsResponseDto>> {
    const rb = new RequestBuilder(this.rootUrl, ReviewApi.WebsiteReviewControllerGetReviewsForWebsitePath, 'get');
    if (params) {
      rb.query('limit', params.limit, {});
      rb.query('offset', params.offset, {});
      rb.query('sortDirection', params.sortDirection, {});
      rb.query('rating', params.rating, {});
      rb.query('hidden', params.hidden, {});
      rb.query('podcastId', params.podcastId, {});
      rb.query('podcastSlug', params.podcastSlug, {});
      rb.query('podcastType', params.podcastType, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListReviewsResponseDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteReviewControllerGetReviewsForWebsite$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteReviewControllerGetReviewsForWebsite(
    params?: {
      limit?: number;
      offset?: number;
      sortDirection?: 'asc' | 'desc';
      rating?: number;
      hidden?: boolean;
      podcastId?: Array<string>;
      podcastSlug?: string;
      podcastType?: PodcastType;
    },
    context?: HttpContext
  ): Observable<ListReviewsResponseDto> {
    return this.websiteReviewControllerGetReviewsForWebsite$Response(params, context).pipe(
      map((r: StrictHttpResponse<ListReviewsResponseDto>): ListReviewsResponseDto => r.body)
    );
  }

  /** Path part for operation `websiteReviewControllerCreateReview()` */
  static readonly WebsiteReviewControllerCreateReviewPath = '/api/website/podcast/review';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteReviewControllerCreateReview()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  websiteReviewControllerCreateReview$Response(
    params: {
      body: CreateReviewDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ReviewDto>> {
    const rb = new RequestBuilder(this.rootUrl, ReviewApi.WebsiteReviewControllerCreateReviewPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReviewDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteReviewControllerCreateReview$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  websiteReviewControllerCreateReview(
    params: {
      body: CreateReviewDto
    },
    context?: HttpContext
  ): Observable<ReviewDto> {
    return this.websiteReviewControllerCreateReview$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReviewDto>): ReviewDto => r.body)
    );
  }

}
