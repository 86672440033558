/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { ListWebsiteMembershipsDto } from '../models/list-website-memberships-dto';
import { WebsiteMembershipInviteMemberRequestDto } from '../models/website-membership-invite-member-request-dto';

@Injectable({ providedIn: 'root' })
export class WebsiteMembershipApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `websiteMembershipControllerListMembers()` */
  static readonly WebsiteMembershipControllerListMembersPath = '/api/website/{websiteId}/membership';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteMembershipControllerListMembers()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteMembershipControllerListMembers$Response(
    params: {
      websiteId: string;
      limit?: number;
      offset?: number;
      sortBy?: string;
      sortDirection?: 'asc' | 'desc';
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ListWebsiteMembershipsDto>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteMembershipApi.WebsiteMembershipControllerListMembersPath, 'get');
    if (params) {
      rb.path('websiteId', params.websiteId, {});
      rb.query('limit', params.limit, {});
      rb.query('offset', params.offset, {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('sortDirection', params.sortDirection, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListWebsiteMembershipsDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteMembershipControllerListMembers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteMembershipControllerListMembers(
    params: {
      websiteId: string;
      limit?: number;
      offset?: number;
      sortBy?: string;
      sortDirection?: 'asc' | 'desc';
    },
    context?: HttpContext
  ): Observable<ListWebsiteMembershipsDto> {
    return this.websiteMembershipControllerListMembers$Response(params, context).pipe(
      map((r: StrictHttpResponse<ListWebsiteMembershipsDto>): ListWebsiteMembershipsDto => r.body)
    );
  }

  /** Path part for operation `websiteMembershipControllerInviteMember()` */
  static readonly WebsiteMembershipControllerInviteMemberPath = '/api/website/{websiteId}/membership/invite-member';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteMembershipControllerInviteMember()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  websiteMembershipControllerInviteMember$Response(
    params: {
      websiteId: string;
      body: WebsiteMembershipInviteMemberRequestDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteMembershipApi.WebsiteMembershipControllerInviteMemberPath, 'post');
    if (params) {
      rb.path('websiteId', params.websiteId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteMembershipControllerInviteMember$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  websiteMembershipControllerInviteMember(
    params: {
      websiteId: string;
      body: WebsiteMembershipInviteMemberRequestDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.websiteMembershipControllerInviteMember$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
