/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { DefaultTemplateDto } from '../models/default-template-dto';
import { ThemeDto } from '../models/theme-dto';
import { UpdateThemeDto } from '../models/update-theme-dto';

@Injectable({ providedIn: 'root' })
export class ThemeApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `themeControllerGetDefaultTemplates()` */
  static readonly ThemeControllerGetDefaultTemplatesPath = '/api/theme/templates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `themeControllerGetDefaultTemplates()` instead.
   *
   * This method doesn't expect any request body.
   */
  themeControllerGetDefaultTemplates$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<DefaultTemplateDto>>> {
    const rb = new RequestBuilder(this.rootUrl, ThemeApi.ThemeControllerGetDefaultTemplatesPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DefaultTemplateDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `themeControllerGetDefaultTemplates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  themeControllerGetDefaultTemplates(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<DefaultTemplateDto>> {
    return this.themeControllerGetDefaultTemplates$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DefaultTemplateDto>>): Array<DefaultTemplateDto> => r.body)
    );
  }

  /** Path part for operation `themeControllerGetThemeById()` */
  static readonly ThemeControllerGetThemeByIdPath = '/api/theme/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `themeControllerGetThemeById()` instead.
   *
   * This method doesn't expect any request body.
   */
  themeControllerGetThemeById$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ThemeDto>> {
    const rb = new RequestBuilder(this.rootUrl, ThemeApi.ThemeControllerGetThemeByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ThemeDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `themeControllerGetThemeById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  themeControllerGetThemeById(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<ThemeDto> {
    return this.themeControllerGetThemeById$Response(params, context).pipe(
      map((r: StrictHttpResponse<ThemeDto>): ThemeDto => r.body)
    );
  }

  /** Path part for operation `themeControllerGetTheme()` */
  static readonly ThemeControllerGetThemePath = '/api/theme';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `themeControllerGetTheme()` instead.
   *
   * This method doesn't expect any request body.
   */
  themeControllerGetTheme$Response(
    params?: {
      website?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ThemeDto>> {
    const rb = new RequestBuilder(this.rootUrl, ThemeApi.ThemeControllerGetThemePath, 'get');
    if (params) {
      rb.query('website', params.website, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ThemeDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `themeControllerGetTheme$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  themeControllerGetTheme(
    params?: {
      website?: string;
    },
    context?: HttpContext
  ): Observable<ThemeDto> {
    return this.themeControllerGetTheme$Response(params, context).pipe(
      map((r: StrictHttpResponse<ThemeDto>): ThemeDto => r.body)
    );
  }

  /** Path part for operation `themeControllerUpdateTheme()` */
  static readonly ThemeControllerUpdateThemePath = '/api/theme/{themeId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `themeControllerUpdateTheme()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  themeControllerUpdateTheme$Response(
    params: {
      themeId: string;
      body: UpdateThemeDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ThemeDto>> {
    const rb = new RequestBuilder(this.rootUrl, ThemeApi.ThemeControllerUpdateThemePath, 'patch');
    if (params) {
      rb.path('themeId', params.themeId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ThemeDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `themeControllerUpdateTheme$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  themeControllerUpdateTheme(
    params: {
      themeId: string;
      body: UpdateThemeDto
    },
    context?: HttpContext
  ): Observable<ThemeDto> {
    return this.themeControllerUpdateTheme$Response(params, context).pipe(
      map((r: StrictHttpResponse<ThemeDto>): ThemeDto => r.body)
    );
  }

  /** Path part for operation `themeControllerResetTheme()` */
  static readonly ThemeControllerResetThemePath = '/api/theme/{themeId}/reset';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `themeControllerResetTheme()` instead.
   *
   * This method doesn't expect any request body.
   */
  themeControllerResetTheme$Response(
    params: {
      themeId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ThemeDto>> {
    const rb = new RequestBuilder(this.rootUrl, ThemeApi.ThemeControllerResetThemePath, 'put');
    if (params) {
      rb.path('themeId', params.themeId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ThemeDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `themeControllerResetTheme$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  themeControllerResetTheme(
    params: {
      themeId: string;
    },
    context?: HttpContext
  ): Observable<ThemeDto> {
    return this.themeControllerResetTheme$Response(params, context).pipe(
      map((r: StrictHttpResponse<ThemeDto>): ThemeDto => r.body)
    );
  }

  /** Path part for operation `themeControllerUpdateDefaultTheme()` */
  static readonly ThemeControllerUpdateDefaultThemePath = '/api/theme/update-default-theme';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `themeControllerUpdateDefaultTheme()` instead.
   *
   * This method doesn't expect any request body.
   */
  themeControllerUpdateDefaultTheme$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<{
}>>> {
    const rb = new RequestBuilder(this.rootUrl, ThemeApi.ThemeControllerUpdateDefaultThemePath, 'post');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<{
        }>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `themeControllerUpdateDefaultTheme$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  themeControllerUpdateDefaultTheme(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<{
}>> {
    return this.themeControllerUpdateDefaultTheme$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<{
}>>): Array<{
}> => r.body)
    );
  }

}
