import { Injectable } from '@angular/core';

import { SubscriptionPlansService } from '../../services/subscription-plans/subscription-plans.service';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionPlansResolver  {
  constructor(private subscriptionPlansService: SubscriptionPlansService) { }

  resolve() {
    return this.subscriptionPlansService.fetchPlans();
  }

  
}