/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { CreateUserResponseDto } from '../models/create-user-response-dto';
import { CreateWebsiteUserPayloadDto } from '../models/create-website-user-payload-dto';
import { ForgotPasswordDto } from '../models/forgot-password-dto';
import { LoginDto } from '../models/login-dto';
import { LoginResponseDto } from '../models/login-response-dto';
import { ResetPasswordDto } from '../models/reset-password-dto';
import { WebsiteUserDto } from '../models/website-user-dto';
import { WebsiteUserLoginResponseDto } from '../models/website-user-login-response-dto';

@Injectable({ providedIn: 'root' })
export class WebsiteUserApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `websiteUserControllerSignUpWithPassword()` */
  static readonly WebsiteUserControllerSignUpWithPasswordPath = '/api/website-user/signup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteUserControllerSignUpWithPassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  websiteUserControllerSignUpWithPassword$Response(
    params: {
      domain?: string;
      body: CreateWebsiteUserPayloadDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CreateUserResponseDto>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteUserApi.WebsiteUserControllerSignUpWithPasswordPath, 'post');
    if (params) {
      rb.header('domain', params.domain, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreateUserResponseDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteUserControllerSignUpWithPassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  websiteUserControllerSignUpWithPassword(
    params: {
      domain?: string;
      body: CreateWebsiteUserPayloadDto
    },
    context?: HttpContext
  ): Observable<CreateUserResponseDto> {
    return this.websiteUserControllerSignUpWithPassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateUserResponseDto>): CreateUserResponseDto => r.body)
    );
  }

  /** Path part for operation `websiteUserControllerLogin()` */
  static readonly WebsiteUserControllerLoginPath = '/api/website-user/login';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteUserControllerLogin()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  websiteUserControllerLogin$Response(
    params: {
      domain?: string;
      body: LoginDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<WebsiteUserLoginResponseDto>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteUserApi.WebsiteUserControllerLoginPath, 'post');
    if (params) {
      rb.header('domain', params.domain, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WebsiteUserLoginResponseDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteUserControllerLogin$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  websiteUserControllerLogin(
    params: {
      domain?: string;
      body: LoginDto
    },
    context?: HttpContext
  ): Observable<WebsiteUserLoginResponseDto> {
    return this.websiteUserControllerLogin$Response(params, context).pipe(
      map((r: StrictHttpResponse<WebsiteUserLoginResponseDto>): WebsiteUserLoginResponseDto => r.body)
    );
  }

  /** Path part for operation `websiteUserControllerGetUser()` */
  static readonly WebsiteUserControllerGetUserPath = '/api/website-user';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteUserControllerGetUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteUserControllerGetUser$Response(
    params?: {
      domain?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<WebsiteUserDto>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteUserApi.WebsiteUserControllerGetUserPath, 'get');
    if (params) {
      rb.header('domain', params.domain, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WebsiteUserDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteUserControllerGetUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteUserControllerGetUser(
    params?: {
      domain?: string;
    },
    context?: HttpContext
  ): Observable<WebsiteUserDto> {
    return this.websiteUserControllerGetUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<WebsiteUserDto>): WebsiteUserDto => r.body)
    );
  }

  /** Path part for operation `websiteUserControllerDeleteAccount()` */
  static readonly WebsiteUserControllerDeleteAccountPath = '/api/website-user/account';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteUserControllerDeleteAccount()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteUserControllerDeleteAccount$Response(
    params?: {
      domain?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteUserApi.WebsiteUserControllerDeleteAccountPath, 'delete');
    if (params) {
      rb.header('domain', params.domain, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteUserControllerDeleteAccount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteUserControllerDeleteAccount(
    params?: {
      domain?: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.websiteUserControllerDeleteAccount$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `websiteUserControllerForgotPassword()` */
  static readonly WebsiteUserControllerForgotPasswordPath = '/api/website-user/forgot-password';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteUserControllerForgotPassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  websiteUserControllerForgotPassword$Response(
    params: {
      domain?: string;
      body: ForgotPasswordDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteUserApi.WebsiteUserControllerForgotPasswordPath, 'post');
    if (params) {
      rb.header('domain', params.domain, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteUserControllerForgotPassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  websiteUserControllerForgotPassword(
    params: {
      domain?: string;
      body: ForgotPasswordDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.websiteUserControllerForgotPassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `websiteUserControllerResetPassword()` */
  static readonly WebsiteUserControllerResetPasswordPath = '/api/website-user/reset-password';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteUserControllerResetPassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  websiteUserControllerResetPassword$Response(
    params: {
      domain?: string;
      body: ResetPasswordDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<LoginResponseDto>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteUserApi.WebsiteUserControllerResetPasswordPath, 'post');
    if (params) {
      rb.header('domain', params.domain, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoginResponseDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteUserControllerResetPassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  websiteUserControllerResetPassword(
    params: {
      domain?: string;
      body: ResetPasswordDto
    },
    context?: HttpContext
  ): Observable<LoginResponseDto> {
    return this.websiteUserControllerResetPassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<LoginResponseDto>): LoginResponseDto => r.body)
    );
  }

}
