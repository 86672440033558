<div class="reset-page" [ngStyle]="{'background-image': 'url(assets/login_shape.svg)'}">
  <div>
    <img class="logo text-center" src="assets/podcastpage_wide_logo_white.svg" />
    <div class="reset-container">
      <div>
        <h1 class="mb-4 text-5xl">Forgot Password</h1>
        <div class="rpw-subtitle text-2xl mb-10">Receive a reset password link by email.</div>
        <ng-container *ngIf="!emailSent; else emailSentTemplate;">
          <form [formGroup]="form" (ngSubmit)="formSubmit(form)">
            <div class="mb-8 relative">
              <input formControlName="email" placeholder=" " name="email" type="text" class="default" />
              <label for="email">Email Address</label>
              <p class="error-label" *ngIf="email.invalid && (email.dirty || email.touched)">
                <ng-container *ngIf="email.errors.required">Email is required.</ng-container>
                <ng-container *ngIf="email.errors.email">Invalid email address.</ng-container>
              </p>
            </div>

            <div>
              <button class="btn block w-full submit">Reset Password</button>
              <div class="login-link text-2xl mt-12"><a routerLink="/login">Go back</a></div>
            </div>

          </form>
        </ng-container>
        <ng-template #emailSentTemplate>
          <u>Reset password instructions were sent to your email.</u>
        </ng-template>
      </div>
    </div>
  </div>
</div>

<ng-progress #loader id="reset-password-loader"></ng-progress>