import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {

  constructor(
    private location: Location,
    public route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
  }

  goBack() {
    this.location.back();
  }

}
