/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';


@Injectable({ providedIn: 'root' })
export class AnalyticsApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `analyticsControllerCreateAnalyticsSite()` */
  static readonly AnalyticsControllerCreateAnalyticsSitePath = '/api/analytics/{siteId}/{domain}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `analyticsControllerCreateAnalyticsSite()` instead.
   *
   * This method doesn't expect any request body.
   */
  analyticsControllerCreateAnalyticsSite$Response(
    params: {
      siteId: string;
      domain: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
}>> {
    const rb = new RequestBuilder(this.rootUrl, AnalyticsApi.AnalyticsControllerCreateAnalyticsSitePath, 'post');
    if (params) {
      rb.path('siteId', params.siteId, {});
      rb.path('domain', params.domain, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `analyticsControllerCreateAnalyticsSite$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  analyticsControllerCreateAnalyticsSite(
    params: {
      siteId: string;
      domain: string;
    },
    context?: HttpContext
  ): Observable<{
}> {
    return this.analyticsControllerCreateAnalyticsSite$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `analyticsControllerDeleteAnalyticsSite()` */
  static readonly AnalyticsControllerDeleteAnalyticsSitePath = '/api/analytics/{siteId}/{domain}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `analyticsControllerDeleteAnalyticsSite()` instead.
   *
   * This method doesn't expect any request body.
   */
  analyticsControllerDeleteAnalyticsSite$Response(
    params: {
      siteId: string;
      domain: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
}>> {
    const rb = new RequestBuilder(this.rootUrl, AnalyticsApi.AnalyticsControllerDeleteAnalyticsSitePath, 'delete');
    if (params) {
      rb.path('siteId', params.siteId, {});
      rb.path('domain', params.domain, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `analyticsControllerDeleteAnalyticsSite$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  analyticsControllerDeleteAnalyticsSite(
    params: {
      siteId: string;
      domain: string;
    },
    context?: HttpContext
  ): Observable<{
}> {
    return this.analyticsControllerDeleteAnalyticsSite$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `analyticsControllerUpdateAnalyticsSite()` */
  static readonly AnalyticsControllerUpdateAnalyticsSitePath = '/api/analytics/update-analytics/{domain}/{newDomain}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `analyticsControllerUpdateAnalyticsSite()` instead.
   *
   * This method doesn't expect any request body.
   */
  analyticsControllerUpdateAnalyticsSite$Response(
    params: {
      domain: string;
      newDomain: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
}>> {
    const rb = new RequestBuilder(this.rootUrl, AnalyticsApi.AnalyticsControllerUpdateAnalyticsSitePath, 'post');
    if (params) {
      rb.path('domain', params.domain, {});
      rb.path('newDomain', params.newDomain, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `analyticsControllerUpdateAnalyticsSite$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  analyticsControllerUpdateAnalyticsSite(
    params: {
      domain: string;
      newDomain: string;
    },
    context?: HttpContext
  ): Observable<{
}> {
    return this.analyticsControllerUpdateAnalyticsSite$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `analyticsControllerGetAnalyticsEmbed()` */
  static readonly AnalyticsControllerGetAnalyticsEmbedPath = '/api/analytics/analytics-dashboard/{domain}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `analyticsControllerGetAnalyticsEmbed()` instead.
   *
   * This method doesn't expect any request body.
   */
  analyticsControllerGetAnalyticsEmbed$Response(
    params: {
      domain: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, AnalyticsApi.AnalyticsControllerGetAnalyticsEmbedPath, 'get');
    if (params) {
      rb.path('domain', params.domain, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `analyticsControllerGetAnalyticsEmbed$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  analyticsControllerGetAnalyticsEmbed(
    params: {
      domain: string;
    },
    context?: HttpContext
  ): Observable<string> {
    return this.analyticsControllerGetAnalyticsEmbed$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

}
