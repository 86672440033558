/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { CreateMembershipPlanPaymentOptionDto } from '../models/create-membership-plan-payment-option-dto';
import { CreateWebsiteMembershipPlanRequestBodyDto } from '../models/create-website-membership-plan-request-body-dto';
import { CreateWebsiteMembershipRulesDto } from '../models/create-website-membership-rules-dto';
import { DeleteWebsiteMembershipRulesDto } from '../models/delete-website-membership-rules-dto';
import { GetWebsiteMembershipPlanResponseDto } from '../models/get-website-membership-plan-response-dto';
import { ListWebsiteMembershipPlansResponseItemDto } from '../models/list-website-membership-plans-response-item-dto';
import { MembershipRuleWithResourceSnapshotDto } from '../models/membership-rule-with-resource-snapshot-dto';
import { UpdateMembershipPlanPaymentOptionDto } from '../models/update-membership-plan-payment-option-dto';
import { UpdateWebsiteMembershipPlanRequestBodyDto } from '../models/update-website-membership-plan-request-body-dto';
import { WebsiteMembershipPlanDto } from '../models/website-membership-plan-dto';

@Injectable({ providedIn: 'root' })
export class WebsiteMembershipPlanApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `websiteMembershipPlanControllerListPlans()` */
  static readonly WebsiteMembershipPlanControllerListPlansPath = '/api/website/{websiteId}/membership/plan';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteMembershipPlanControllerListPlans()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteMembershipPlanControllerListPlans$Response(
    params: {
      websiteId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ListWebsiteMembershipPlansResponseItemDto>>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteMembershipPlanApi.WebsiteMembershipPlanControllerListPlansPath, 'get');
    if (params) {
      rb.path('websiteId', params.websiteId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ListWebsiteMembershipPlansResponseItemDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteMembershipPlanControllerListPlans$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteMembershipPlanControllerListPlans(
    params: {
      websiteId: string;
    },
    context?: HttpContext
  ): Observable<Array<ListWebsiteMembershipPlansResponseItemDto>> {
    return this.websiteMembershipPlanControllerListPlans$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ListWebsiteMembershipPlansResponseItemDto>>): Array<ListWebsiteMembershipPlansResponseItemDto> => r.body)
    );
  }

  /** Path part for operation `websiteMembershipPlanControllerCreatePlan()` */
  static readonly WebsiteMembershipPlanControllerCreatePlanPath = '/api/website/{websiteId}/membership/plan';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteMembershipPlanControllerCreatePlan()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  websiteMembershipPlanControllerCreatePlan$Response(
    params: {
      websiteId: string;
      body: CreateWebsiteMembershipPlanRequestBodyDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<WebsiteMembershipPlanDto>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteMembershipPlanApi.WebsiteMembershipPlanControllerCreatePlanPath, 'post');
    if (params) {
      rb.path('websiteId', params.websiteId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WebsiteMembershipPlanDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteMembershipPlanControllerCreatePlan$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  websiteMembershipPlanControllerCreatePlan(
    params: {
      websiteId: string;
      body: CreateWebsiteMembershipPlanRequestBodyDto
    },
    context?: HttpContext
  ): Observable<WebsiteMembershipPlanDto> {
    return this.websiteMembershipPlanControllerCreatePlan$Response(params, context).pipe(
      map((r: StrictHttpResponse<WebsiteMembershipPlanDto>): WebsiteMembershipPlanDto => r.body)
    );
  }

  /** Path part for operation `websiteMembershipPlanControllerListRules()` */
  static readonly WebsiteMembershipPlanControllerListRulesPath = '/api/website/{websiteId}/membership/plan/rules';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteMembershipPlanControllerListRules()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteMembershipPlanControllerListRules$Response(
    params: {
      websiteId: string;
      planId?: string;
      resourceId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MembershipRuleWithResourceSnapshotDto>>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteMembershipPlanApi.WebsiteMembershipPlanControllerListRulesPath, 'get');
    if (params) {
      rb.path('websiteId', params.websiteId, {});
      rb.query('planId', params.planId, {});
      rb.query('resourceId', params.resourceId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MembershipRuleWithResourceSnapshotDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteMembershipPlanControllerListRules$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteMembershipPlanControllerListRules(
    params: {
      websiteId: string;
      planId?: string;
      resourceId?: string;
    },
    context?: HttpContext
  ): Observable<Array<MembershipRuleWithResourceSnapshotDto>> {
    return this.websiteMembershipPlanControllerListRules$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MembershipRuleWithResourceSnapshotDto>>): Array<MembershipRuleWithResourceSnapshotDto> => r.body)
    );
  }

  /** Path part for operation `websiteMembershipPlanControllerCreateRules()` */
  static readonly WebsiteMembershipPlanControllerCreateRulesPath = '/api/website/{websiteId}/membership/plan/rules';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteMembershipPlanControllerCreateRules()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  websiteMembershipPlanControllerCreateRules$Response(
    params: {
      websiteId: string;
      body: CreateWebsiteMembershipRulesDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteMembershipPlanApi.WebsiteMembershipPlanControllerCreateRulesPath, 'post');
    if (params) {
      rb.path('websiteId', params.websiteId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteMembershipPlanControllerCreateRules$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  websiteMembershipPlanControllerCreateRules(
    params: {
      websiteId: string;
      body: CreateWebsiteMembershipRulesDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.websiteMembershipPlanControllerCreateRules$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `websiteMembershipPlanControllerDeleteRules()` */
  static readonly WebsiteMembershipPlanControllerDeleteRulesPath = '/api/website/{websiteId}/membership/plan/rules';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteMembershipPlanControllerDeleteRules()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  websiteMembershipPlanControllerDeleteRules$Response(
    params: {
      websiteId: string;
      body: DeleteWebsiteMembershipRulesDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteMembershipPlanApi.WebsiteMembershipPlanControllerDeleteRulesPath, 'delete');
    if (params) {
      rb.path('websiteId', params.websiteId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteMembershipPlanControllerDeleteRules$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  websiteMembershipPlanControllerDeleteRules(
    params: {
      websiteId: string;
      body: DeleteWebsiteMembershipRulesDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.websiteMembershipPlanControllerDeleteRules$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `websiteMembershipPlanControllerGetPlan()` */
  static readonly WebsiteMembershipPlanControllerGetPlanPath = '/api/website/{websiteId}/membership/plan/{planId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteMembershipPlanControllerGetPlan()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteMembershipPlanControllerGetPlan$Response(
    params: {
      websiteId: string;
      planId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<GetWebsiteMembershipPlanResponseDto>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteMembershipPlanApi.WebsiteMembershipPlanControllerGetPlanPath, 'get');
    if (params) {
      rb.path('websiteId', params.websiteId, {});
      rb.path('planId', params.planId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetWebsiteMembershipPlanResponseDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteMembershipPlanControllerGetPlan$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteMembershipPlanControllerGetPlan(
    params: {
      websiteId: string;
      planId: string;
    },
    context?: HttpContext
  ): Observable<GetWebsiteMembershipPlanResponseDto> {
    return this.websiteMembershipPlanControllerGetPlan$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetWebsiteMembershipPlanResponseDto>): GetWebsiteMembershipPlanResponseDto => r.body)
    );
  }

  /** Path part for operation `websiteMembershipPlanControllerDeletePlan()` */
  static readonly WebsiteMembershipPlanControllerDeletePlanPath = '/api/website/{websiteId}/membership/plan/{planId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteMembershipPlanControllerDeletePlan()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteMembershipPlanControllerDeletePlan$Response(
    params: {
      websiteId: string;
      planId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteMembershipPlanApi.WebsiteMembershipPlanControllerDeletePlanPath, 'delete');
    if (params) {
      rb.path('websiteId', params.websiteId, {});
      rb.path('planId', params.planId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteMembershipPlanControllerDeletePlan$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteMembershipPlanControllerDeletePlan(
    params: {
      websiteId: string;
      planId: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.websiteMembershipPlanControllerDeletePlan$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `websiteMembershipPlanControllerUpdatePlan()` */
  static readonly WebsiteMembershipPlanControllerUpdatePlanPath = '/api/website/{websiteId}/membership/plan/{planId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteMembershipPlanControllerUpdatePlan()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  websiteMembershipPlanControllerUpdatePlan$Response(
    params: {
      websiteId: string;
      planId: string;
      body: UpdateWebsiteMembershipPlanRequestBodyDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteMembershipPlanApi.WebsiteMembershipPlanControllerUpdatePlanPath, 'patch');
    if (params) {
      rb.path('websiteId', params.websiteId, {});
      rb.path('planId', params.planId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteMembershipPlanControllerUpdatePlan$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  websiteMembershipPlanControllerUpdatePlan(
    params: {
      websiteId: string;
      planId: string;
      body: UpdateWebsiteMembershipPlanRequestBodyDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.websiteMembershipPlanControllerUpdatePlan$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `websiteMembershipPlanControllerActivatePlan()` */
  static readonly WebsiteMembershipPlanControllerActivatePlanPath = '/api/website/{websiteId}/membership/plan/{planId}/activate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteMembershipPlanControllerActivatePlan()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteMembershipPlanControllerActivatePlan$Response(
    params: {
      websiteId: string;
      planId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteMembershipPlanApi.WebsiteMembershipPlanControllerActivatePlanPath, 'post');
    if (params) {
      rb.path('websiteId', params.websiteId, {});
      rb.path('planId', params.planId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteMembershipPlanControllerActivatePlan$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteMembershipPlanControllerActivatePlan(
    params: {
      websiteId: string;
      planId: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.websiteMembershipPlanControllerActivatePlan$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `websiteMembershipPlanControllerDeactivatePlan()` */
  static readonly WebsiteMembershipPlanControllerDeactivatePlanPath = '/api/website/{websiteId}/membership/plan/{planId}/deactivate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteMembershipPlanControllerDeactivatePlan()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteMembershipPlanControllerDeactivatePlan$Response(
    params: {
      websiteId: string;
      planId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteMembershipPlanApi.WebsiteMembershipPlanControllerDeactivatePlanPath, 'post');
    if (params) {
      rb.path('websiteId', params.websiteId, {});
      rb.path('planId', params.planId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteMembershipPlanControllerDeactivatePlan$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteMembershipPlanControllerDeactivatePlan(
    params: {
      websiteId: string;
      planId: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.websiteMembershipPlanControllerDeactivatePlan$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `websiteMembershipPlanControllerAddPaymentOption()` */
  static readonly WebsiteMembershipPlanControllerAddPaymentOptionPath = '/api/website/{websiteId}/membership/plan/{planId}/payment-option';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteMembershipPlanControllerAddPaymentOption()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  websiteMembershipPlanControllerAddPaymentOption$Response(
    params: {
      websiteId: string;
      planId: string;
      body: CreateMembershipPlanPaymentOptionDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteMembershipPlanApi.WebsiteMembershipPlanControllerAddPaymentOptionPath, 'post');
    if (params) {
      rb.path('websiteId', params.websiteId, {});
      rb.path('planId', params.planId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteMembershipPlanControllerAddPaymentOption$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  websiteMembershipPlanControllerAddPaymentOption(
    params: {
      websiteId: string;
      planId: string;
      body: CreateMembershipPlanPaymentOptionDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.websiteMembershipPlanControllerAddPaymentOption$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `websiteMembershipPlanControllerDeletePlanPaymentOption()` */
  static readonly WebsiteMembershipPlanControllerDeletePlanPaymentOptionPath = '/api/website/{websiteId}/membership/plan/{planId}/payment-option/{paymentOptionId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteMembershipPlanControllerDeletePlanPaymentOption()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteMembershipPlanControllerDeletePlanPaymentOption$Response(
    params: {
      websiteId: string;
      planId: string;
      paymentOptionId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteMembershipPlanApi.WebsiteMembershipPlanControllerDeletePlanPaymentOptionPath, 'delete');
    if (params) {
      rb.path('websiteId', params.websiteId, {});
      rb.path('planId', params.planId, {});
      rb.path('paymentOptionId', params.paymentOptionId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteMembershipPlanControllerDeletePlanPaymentOption$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteMembershipPlanControllerDeletePlanPaymentOption(
    params: {
      websiteId: string;
      planId: string;
      paymentOptionId: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.websiteMembershipPlanControllerDeletePlanPaymentOption$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `websiteMembershipPlanControllerUpdatePlanPaymentOption()` */
  static readonly WebsiteMembershipPlanControllerUpdatePlanPaymentOptionPath = '/api/website/{websiteId}/membership/plan/{planId}/payment-option/{paymentOptionId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteMembershipPlanControllerUpdatePlanPaymentOption()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  websiteMembershipPlanControllerUpdatePlanPaymentOption$Response(
    params: {
      websiteId: string;
      planId: string;
      paymentOptionId: string;
      body: UpdateMembershipPlanPaymentOptionDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteMembershipPlanApi.WebsiteMembershipPlanControllerUpdatePlanPaymentOptionPath, 'patch');
    if (params) {
      rb.path('websiteId', params.websiteId, {});
      rb.path('planId', params.planId, {});
      rb.path('paymentOptionId', params.paymentOptionId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteMembershipPlanControllerUpdatePlanPaymentOption$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  websiteMembershipPlanControllerUpdatePlanPaymentOption(
    params: {
      websiteId: string;
      planId: string;
      paymentOptionId: string;
      body: UpdateMembershipPlanPaymentOptionDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.websiteMembershipPlanControllerUpdatePlanPaymentOption$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
