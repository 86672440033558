import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SubscriptionApi } from '../../../api/services';
import { tap, switchMap, first, map } from 'rxjs/operators';
import { UserSubscriptionDto, SubscriptionPaymentDto, AddSubscriptionAddonDto, SubscriptionAddonDto, SubscscriptionAddonStatusDto } from '../../../api/models';
import { SubscriptionPlan } from '../../../shared/models/subscription-plan.model';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  private subscription: BehaviorSubject<UserSubscriptionDto> = new BehaviorSubject(null);
  private payments: BehaviorSubject<SubscriptionPaymentDto[]> = new BehaviorSubject([]);

  susbcription$ = this.subscription.asObservable();
  payments$ = this.payments.asObservable();

  constructor(
    private subscriptionApi: SubscriptionApi
  ) { }

  fetchSubscription() {
    return this.subscriptionApi.subscriptionControllerGetSubscription().pipe(
      tap(subscription => this.subscription.next(subscription))
    )
  }

  cancelSubscription() {
    return this.subscriptionApi.subscriptionControllerCancelSubscription();
  }

  changePlan(planId: string) {
    return this.subscriptionApi.subscriptionControllerChangeSubscriptionPlan({
      planId,
    })
  }

  fetchPayments() {
    return this.subscriptionApi.subscriptionControllerGetSubscriptionPayments().pipe(
      tap(payments => this.payments.next(payments?.map(p => Object.assign(p, { payoutDate: new Date(p.payoutDate)}))))
    )
  }

  addSubscriptionAddon(body: AddSubscriptionAddonDto): Observable<void> {
    return this.subscriptionApi.subscriptionControllerAddSubscriptionAddon({
      body
    })
  }

  listAddons(): Observable<SubscriptionAddonDto[]> {
    return this.subscriptionApi.subscriptionControllerListAddons().pipe()
  }

  removeSubscriptionAddon(addonId: string): Observable<void> {
    return this.subscriptionApi.subscriptionControllerRemoveSubscriptionAddon({
      addonId
    })
  }

  switchAddon(addonId: string, selectedAddonId: string): Observable<void> {
    return this.subscriptionApi.subscriptionControllerSwitchAddon({
      addonId,
      body: {
        planAddonId: selectedAddonId
      }
    })
  }
}
