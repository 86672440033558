/* tslint:disable */
/* eslint-disable */
export enum CurrencyCodeDto {
  Usd = 'USD',
  Eur = 'EUR',
  Cad = 'CAD',
  Aud = 'AUD',
  Brl = 'BRL',
  Chf = 'CHF',
  Czk = 'CZK',
  Dkk = 'DKK',
  Gbp = 'GBP',
  Hkd = 'HKD',
  Huf = 'HUF',
  Ils = 'ILS',
  Inr = 'INR',
  Jpy = 'JPY',
  Mxn = 'MXN',
  Nok = 'NOK',
  Nzd = 'NZD',
  Pln = 'PLN',
  Sek = 'SEK',
  Sgd = 'SGD'
}
