import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/guards/auth/auth.guard';
import { ResetPasswordComponent } from './unauth-pages/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './unauth-pages/forgot-password/forgot-password.component';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { SubscriptionPlansResolver } from './shared/resolvers/subscription-plans/subscription-plans.resolver';
import { UserResolver } from './main-layout/resolvers/user/user.resolver';
import { ProcessingResolver } from './main-layout/resolvers/processing/processing.resolver';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./main-layout/main-layout.module').then(m => m.MainLayoutModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    loadChildren: () => import('src/app/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'signup',
    loadChildren: () => import('src/app/signup/signup.module').then(m => m.SignupModule),
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'reset-password/:token',
    component: ResetPasswordComponent,
  },
  {
    path: 'error/:errorCode',
    component: ErrorPageComponent,
  },
  {
    path: 'plans',
    loadComponent: () => import('../app/signup/pages/plans-page/plans-page.component').then(c => c.PlansPageComponent),
    resolve: {
      subscriptionPlans: SubscriptionPlansResolver,
      user: UserResolver
    }
  },
  {
    path: 'processing',
    loadComponent: () => import('../app/signup/pages/processing-page/processing-page.component').then(c => c.ProcessingPageComponent),
    resolve: {
      user: ProcessingResolver
    }
  },
  {
    path: 'templates/:template',
    loadComponent: () => import('../app/signup/pages/template-preview/template-preview.component').then(c => c.TemplatePreviewComponent),
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'error/404',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
