import { Component, OnInit } from '@angular/core';
import { NgProgress } from 'ngx-progressbar';
import { LoaderService } from './shared/services/loader/loader.service';
import { Router, NavigationStart, NavigationEnd, NavigationCancel } from '@angular/router';
import { environment } from 'src/environments/environment';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private ngProgress: NgProgress,
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.ngProgress.ref('app-loader').start()
      }
      if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        this.ngProgress.ref('app-loader').complete();
        window.scrollTo(0, 0);
      }
    })

    // block dashboard analytics (plausible) on dev/staging
    if (environment.name !== 'production') window.localStorage.setItem("plausible_ignore", "true");

  }
}
