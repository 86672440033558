import { SubscriptionPlanDto, SubscriptionPlanFeaturesDto } from '../../api/models';
import { DurationType } from './duration-type.type';

export interface SubscriptionPlan {
  id: string;
  paddleId: number;
  name: string;
  description: string;
  initialPrice: number;
  recurringPrice: number;
  currency: string;
  duration: number;
  durationType: DurationType;
  features: SubscriptionPlanFeaturesDto;
  // trialDays: number;
}

export class SubscriptionPlan {
  static fromApi(subscription: SubscriptionPlanDto) {
    const instance = new SubscriptionPlan();
    instance.id = subscription.id;
    instance.paddleId = subscription.paddleId;
    instance.name = subscription.name;
    instance.description = subscription.description;
    instance.initialPrice = subscription.initialPrice;
    instance.recurringPrice = subscription.recurringPrice;
    instance.duration = subscription.duration;
    instance.durationType = subscription.durationType as DurationType;
    instance.currency = subscription.currency;
    instance.features = subscription.features;
    // instance.trialDays = subscription.tria;
    return instance;
  }
}