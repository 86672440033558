/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { CreatePageDto } from '../models/create-page-dto';
import { GetPageByCriteriaDto } from '../models/get-page-by-criteria-dto';
import { GetPageRedirectsResponseItemDto } from '../models/get-page-redirects-response-item-dto';
import { ListPagesResponseDto } from '../models/list-pages-response-dto';
import { UpdatePageDto } from '../models/update-page-dto';

@Injectable({ providedIn: 'root' })
export class PageApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `pageControllerGetPages()` */
  static readonly PageControllerGetPagesPath = '/api/page';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pageControllerGetPages()` instead.
   *
   * This method doesn't expect any request body.
   */
  pageControllerGetPages$Response(
    params: {
      isDraft?: boolean;
      isHomepage?: boolean;
      isPageBuilder?: boolean;
      limit?: number;
      offset?: number;
      sortDirection?: 'asc' | 'desc';
      website: string;
      title?: string;
      slug?: string;
      body?: string;
      headerBgImgUrl?: string;
      redirectUrl?: string;
      seoTitle?: string;
      seoDescription?: string;
      seoImage?: string;
      seoKeyphrase?: string;
      pageBuilderData?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ListPagesResponseDto>> {
    const rb = new RequestBuilder(this.rootUrl, PageApi.PageControllerGetPagesPath, 'get');
    if (params) {
      rb.query('isDraft', params.isDraft, {});
      rb.query('isHomepage', params.isHomepage, {});
      rb.query('isPageBuilder', params.isPageBuilder, {});
      rb.query('limit', params.limit, {});
      rb.query('offset', params.offset, {});
      rb.query('sortDirection', params.sortDirection, {});
      rb.query('website', params.website, {});
      rb.query('title', params.title, {});
      rb.query('slug', params.slug, {});
      rb.query('body', params.body, {});
      rb.query('headerBgImgUrl', params.headerBgImgUrl, {});
      rb.query('redirectUrl', params.redirectUrl, {});
      rb.query('seoTitle', params.seoTitle, {});
      rb.query('seoDescription', params.seoDescription, {});
      rb.query('seoImage', params.seoImage, {});
      rb.query('seoKeyphrase', params.seoKeyphrase, {});
      rb.query('pageBuilderData', params.pageBuilderData, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListPagesResponseDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pageControllerGetPages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pageControllerGetPages(
    params: {
      isDraft?: boolean;
      isHomepage?: boolean;
      isPageBuilder?: boolean;
      limit?: number;
      offset?: number;
      sortDirection?: 'asc' | 'desc';
      website: string;
      title?: string;
      slug?: string;
      body?: string;
      headerBgImgUrl?: string;
      redirectUrl?: string;
      seoTitle?: string;
      seoDescription?: string;
      seoImage?: string;
      seoKeyphrase?: string;
      pageBuilderData?: string;
    },
    context?: HttpContext
  ): Observable<ListPagesResponseDto> {
    return this.pageControllerGetPages$Response(params, context).pipe(
      map((r: StrictHttpResponse<ListPagesResponseDto>): ListPagesResponseDto => r.body)
    );
  }

  /** Path part for operation `pageControllerCreatePage()` */
  static readonly PageControllerCreatePagePath = '/api/page';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pageControllerCreatePage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pageControllerCreatePage$Response(
    params: {
      body: CreatePageDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<{
}>>> {
    const rb = new RequestBuilder(this.rootUrl, PageApi.PageControllerCreatePagePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<{
        }>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pageControllerCreatePage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pageControllerCreatePage(
    params: {
      body: CreatePageDto
    },
    context?: HttpContext
  ): Observable<Array<{
}>> {
    return this.pageControllerCreatePage$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<{
}>>): Array<{
}> => r.body)
    );
  }

  /** Path part for operation `pageControllerGetPageRedirects()` */
  static readonly PageControllerGetPageRedirectsPath = '/api/page/redirects';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pageControllerGetPageRedirects()` instead.
   *
   * This method doesn't expect any request body.
   */
  pageControllerGetPageRedirects$Response(
    params: {
      website: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<GetPageRedirectsResponseItemDto>>> {
    const rb = new RequestBuilder(this.rootUrl, PageApi.PageControllerGetPageRedirectsPath, 'get');
    if (params) {
      rb.query('website', params.website, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GetPageRedirectsResponseItemDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pageControllerGetPageRedirects$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pageControllerGetPageRedirects(
    params: {
      website: string;
    },
    context?: HttpContext
  ): Observable<Array<GetPageRedirectsResponseItemDto>> {
    return this.pageControllerGetPageRedirects$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GetPageRedirectsResponseItemDto>>): Array<GetPageRedirectsResponseItemDto> => r.body)
    );
  }

  /** Path part for operation `pageControllerGetPageByCriteria()` */
  static readonly PageControllerGetPageByCriteriaPath = '/api/page/by-criteria';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pageControllerGetPageByCriteria()` instead.
   *
   * This method doesn't expect any request body.
   */
  pageControllerGetPageByCriteria$Response(
    params: {
      isDraft?: boolean;
      isHomepage?: boolean;
      isPageBuilder?: boolean;
      limit?: number;
      offset?: number;
      sortDirection?: 'asc' | 'desc';
      website: string;
      title?: string;
      slug?: string;
      body?: string;
      headerBgImgUrl?: string;
      redirectUrl?: string;
      seoTitle?: string;
      seoDescription?: string;
      seoImage?: string;
      seoKeyphrase?: string;
      pageBuilderData?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<GetPageByCriteriaDto>> {
    const rb = new RequestBuilder(this.rootUrl, PageApi.PageControllerGetPageByCriteriaPath, 'get');
    if (params) {
      rb.query('isDraft', params.isDraft, {});
      rb.query('isHomepage', params.isHomepage, {});
      rb.query('isPageBuilder', params.isPageBuilder, {});
      rb.query('limit', params.limit, {});
      rb.query('offset', params.offset, {});
      rb.query('sortDirection', params.sortDirection, {});
      rb.query('website', params.website, {});
      rb.query('title', params.title, {});
      rb.query('slug', params.slug, {});
      rb.query('body', params.body, {});
      rb.query('headerBgImgUrl', params.headerBgImgUrl, {});
      rb.query('redirectUrl', params.redirectUrl, {});
      rb.query('seoTitle', params.seoTitle, {});
      rb.query('seoDescription', params.seoDescription, {});
      rb.query('seoImage', params.seoImage, {});
      rb.query('seoKeyphrase', params.seoKeyphrase, {});
      rb.query('pageBuilderData', params.pageBuilderData, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetPageByCriteriaDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pageControllerGetPageByCriteria$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pageControllerGetPageByCriteria(
    params: {
      isDraft?: boolean;
      isHomepage?: boolean;
      isPageBuilder?: boolean;
      limit?: number;
      offset?: number;
      sortDirection?: 'asc' | 'desc';
      website: string;
      title?: string;
      slug?: string;
      body?: string;
      headerBgImgUrl?: string;
      redirectUrl?: string;
      seoTitle?: string;
      seoDescription?: string;
      seoImage?: string;
      seoKeyphrase?: string;
      pageBuilderData?: string;
    },
    context?: HttpContext
  ): Observable<GetPageByCriteriaDto> {
    return this.pageControllerGetPageByCriteria$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetPageByCriteriaDto>): GetPageByCriteriaDto => r.body)
    );
  }

  /** Path part for operation `pageControllerGetPage()` */
  static readonly PageControllerGetPagePath = '/api/page/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pageControllerGetPage()` instead.
   *
   * This method doesn't expect any request body.
   */
  pageControllerGetPage$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
}>> {
    const rb = new RequestBuilder(this.rootUrl, PageApi.PageControllerGetPagePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pageControllerGetPage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pageControllerGetPage(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<{
}> {
    return this.pageControllerGetPage$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `pageControllerDeletePage()` */
  static readonly PageControllerDeletePagePath = '/api/page/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pageControllerDeletePage()` instead.
   *
   * This method doesn't expect any request body.
   */
  pageControllerDeletePage$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
}>> {
    const rb = new RequestBuilder(this.rootUrl, PageApi.PageControllerDeletePagePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pageControllerDeletePage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pageControllerDeletePage(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<{
}> {
    return this.pageControllerDeletePage$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `pageControllerUpdatePage()` */
  static readonly PageControllerUpdatePagePath = '/api/page/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pageControllerUpdatePage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pageControllerUpdatePage$Response(
    params: {
      id: string;
      body: UpdatePageDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
}>> {
    const rb = new RequestBuilder(this.rootUrl, PageApi.PageControllerUpdatePagePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pageControllerUpdatePage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pageControllerUpdatePage(
    params: {
      id: string;
      body: UpdatePageDto
    },
    context?: HttpContext
  ): Observable<{
}> {
    return this.pageControllerUpdatePage$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `pageControllerUpdateWebsiteHomepage()` */
  static readonly PageControllerUpdateWebsiteHomepagePath = '/api/page/home/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pageControllerUpdateWebsiteHomepage()` instead.
   *
   * This method doesn't expect any request body.
   */
  pageControllerUpdateWebsiteHomepage$Response(
    params: {
      website: string;
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
}>> {
    const rb = new RequestBuilder(this.rootUrl, PageApi.PageControllerUpdateWebsiteHomepagePath, 'patch');
    if (params) {
      rb.query('website', params.website, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pageControllerUpdateWebsiteHomepage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pageControllerUpdateWebsiteHomepage(
    params: {
      website: string;
      id: string;
    },
    context?: HttpContext
  ): Observable<{
}> {
    return this.pageControllerUpdateWebsiteHomepage$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

}
