import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClientJsonpModule } from '@angular/common/http';
import { AuthModule } from './auth/auth.module';
import { AuthInterceptor } from './auth/interceptors/auth-interceptor/auth.interceptor';
import { ResetPasswordComponent } from './unauth-pages/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './unauth-pages/forgot-password/forgot-password.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { ApiModule } from './api/api.module';
import { environment } from '../environments/environment';
import { NgProgressModule } from 'ngx-progressbar';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    ErrorPageComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    HttpClientJsonpModule,
    CoreModule,
    AuthModule,
    FormsModule,
    ReactiveFormsModule,
    ApiModule.forRoot({ rootUrl: environment.api }),
    BrowserAnimationsModule,
    NgProgressModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
