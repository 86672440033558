/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AppendParticipantRequestDto } from '../models/append-participant-request-dto';
import { CreateManualPodcastDto } from '../models/create-manual-podcast-dto';
import { CreatePodcastFromUrlDto } from '../models/create-podcast-from-url-dto';
import { GetParticipantResponseDto } from '../models/get-participant-response-dto';
import { ListWebsiteInitialPodcastsResponseItemDto } from '../models/list-website-initial-podcasts-response-item-dto';
import { PodcastDto } from '../models/podcast-dto';
import { PodcastType } from '../models/podcast-type';
import { RestrictedPodcastDto } from '../models/restricted-podcast-dto';
import { UpdatePodcastDto } from '../models/update-podcast-dto';

@Injectable({ providedIn: 'root' })
export class PodcastApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `podcastControllerGetPodcastsForWebsite()` */
  static readonly PodcastControllerGetPodcastsForWebsitePath = '/api/podcast/website';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `podcastControllerGetPodcastsForWebsite()` instead.
   *
   * This method doesn't expect any request body.
   */
  podcastControllerGetPodcastsForWebsite$Response(
    params?: {
      website?: string;
      podcastIds?: Array<string>;
      url?: string;
      slug?: string;
      title?: string;
      offset?: number;
      limit?: number;
      type?: PodcastType;
      sortBy?: 'title' | 'createdAt' | 'lastEpisodeSync';
      sortOrder?: 'asc' | 'desc';
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<(PodcastDto | RestrictedPodcastDto)>>> {
    const rb = new RequestBuilder(this.rootUrl, PodcastApi.PodcastControllerGetPodcastsForWebsitePath, 'get');
    if (params) {
      rb.query('website', params.website, {});
      rb.query('podcastIds', params.podcastIds, {});
      rb.query('url', params.url, {});
      rb.query('slug', params.slug, {});
      rb.query('title', params.title, {});
      rb.query('offset', params.offset, {});
      rb.query('limit', params.limit, {});
      rb.query('type', params.type, {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('sortOrder', params.sortOrder, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<(PodcastDto | RestrictedPodcastDto)>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `podcastControllerGetPodcastsForWebsite$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  podcastControllerGetPodcastsForWebsite(
    params?: {
      website?: string;
      podcastIds?: Array<string>;
      url?: string;
      slug?: string;
      title?: string;
      offset?: number;
      limit?: number;
      type?: PodcastType;
      sortBy?: 'title' | 'createdAt' | 'lastEpisodeSync';
      sortOrder?: 'asc' | 'desc';
    },
    context?: HttpContext
  ): Observable<Array<(PodcastDto | RestrictedPodcastDto)>> {
    return this.podcastControllerGetPodcastsForWebsite$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<(PodcastDto | RestrictedPodcastDto)>>): Array<(PodcastDto | RestrictedPodcastDto)> => r.body)
    );
  }

  /** Path part for operation `podcastControllerGetInitialPodcastsForWebsite()` */
  static readonly PodcastControllerGetInitialPodcastsForWebsitePath = '/api/podcast/initial-podcasts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `podcastControllerGetInitialPodcastsForWebsite()` instead.
   *
   * This method doesn't expect any request body.
   */
  podcastControllerGetInitialPodcastsForWebsite$Response(
    params?: {
      website?: string;
      podcastIds?: Array<string>;
      url?: string;
      slug?: string;
      title?: string;
      offset?: number;
      limit?: number;
      type?: PodcastType;
      sortBy?: 'title' | 'createdAt' | 'lastEpisodeSync';
      sortOrder?: 'asc' | 'desc';
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<(RestrictedPodcastDto | ListWebsiteInitialPodcastsResponseItemDto)>>> {
    const rb = new RequestBuilder(this.rootUrl, PodcastApi.PodcastControllerGetInitialPodcastsForWebsitePath, 'get');
    if (params) {
      rb.query('website', params.website, {});
      rb.query('podcastIds', params.podcastIds, {});
      rb.query('url', params.url, {});
      rb.query('slug', params.slug, {});
      rb.query('title', params.title, {});
      rb.query('offset', params.offset, {});
      rb.query('limit', params.limit, {});
      rb.query('type', params.type, {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('sortOrder', params.sortOrder, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<(RestrictedPodcastDto | ListWebsiteInitialPodcastsResponseItemDto)>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `podcastControllerGetInitialPodcastsForWebsite$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  podcastControllerGetInitialPodcastsForWebsite(
    params?: {
      website?: string;
      podcastIds?: Array<string>;
      url?: string;
      slug?: string;
      title?: string;
      offset?: number;
      limit?: number;
      type?: PodcastType;
      sortBy?: 'title' | 'createdAt' | 'lastEpisodeSync';
      sortOrder?: 'asc' | 'desc';
    },
    context?: HttpContext
  ): Observable<Array<(RestrictedPodcastDto | ListWebsiteInitialPodcastsResponseItemDto)>> {
    return this.podcastControllerGetInitialPodcastsForWebsite$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<(RestrictedPodcastDto | ListWebsiteInitialPodcastsResponseItemDto)>>): Array<(RestrictedPodcastDto | ListWebsiteInitialPodcastsResponseItemDto)> => r.body)
    );
  }

  /** Path part for operation `podcastControllerSearchPodcastsForWebsite()` */
  static readonly PodcastControllerSearchPodcastsForWebsitePath = '/api/podcast/search-podcasts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `podcastControllerSearchPodcastsForWebsite()` instead.
   *
   * This method doesn't expect any request body.
   */
  podcastControllerSearchPodcastsForWebsite$Response(
    params?: {
      website?: string;
      podcastIds?: Array<string>;
      url?: string;
      slug?: string;
      title?: string;
      offset?: number;
      limit?: number;
      type?: PodcastType;
      sortBy?: 'title' | 'createdAt' | 'lastEpisodeSync';
      sortOrder?: 'asc' | 'desc';
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<(RestrictedPodcastDto | ListWebsiteInitialPodcastsResponseItemDto)>>> {
    const rb = new RequestBuilder(this.rootUrl, PodcastApi.PodcastControllerSearchPodcastsForWebsitePath, 'get');
    if (params) {
      rb.query('website', params.website, {});
      rb.query('podcastIds', params.podcastIds, {});
      rb.query('url', params.url, {});
      rb.query('slug', params.slug, {});
      rb.query('title', params.title, {});
      rb.query('offset', params.offset, {});
      rb.query('limit', params.limit, {});
      rb.query('type', params.type, {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('sortOrder', params.sortOrder, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<(RestrictedPodcastDto | ListWebsiteInitialPodcastsResponseItemDto)>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `podcastControllerSearchPodcastsForWebsite$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  podcastControllerSearchPodcastsForWebsite(
    params?: {
      website?: string;
      podcastIds?: Array<string>;
      url?: string;
      slug?: string;
      title?: string;
      offset?: number;
      limit?: number;
      type?: PodcastType;
      sortBy?: 'title' | 'createdAt' | 'lastEpisodeSync';
      sortOrder?: 'asc' | 'desc';
    },
    context?: HttpContext
  ): Observable<Array<(RestrictedPodcastDto | ListWebsiteInitialPodcastsResponseItemDto)>> {
    return this.podcastControllerSearchPodcastsForWebsite$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<(RestrictedPodcastDto | ListWebsiteInitialPodcastsResponseItemDto)>>): Array<(RestrictedPodcastDto | ListWebsiteInitialPodcastsResponseItemDto)> => r.body)
    );
  }

  /** Path part for operation `podcastControllerGetPodcasts()` */
  static readonly PodcastControllerGetPodcastsPath = '/api/podcast';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `podcastControllerGetPodcasts()` instead.
   *
   * This method doesn't expect any request body.
   */
  podcastControllerGetPodcasts$Response(
    params?: {
      website?: string;
      podcastIds?: Array<string>;
      url?: string;
      slug?: string;
      title?: string;
      offset?: number;
      limit?: number;
      type?: PodcastType;
      sortBy?: 'title' | 'createdAt' | 'lastEpisodeSync';
      sortOrder?: 'asc' | 'desc';
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ListWebsiteInitialPodcastsResponseItemDto>>> {
    const rb = new RequestBuilder(this.rootUrl, PodcastApi.PodcastControllerGetPodcastsPath, 'get');
    if (params) {
      rb.query('website', params.website, {});
      rb.query('podcastIds', params.podcastIds, {});
      rb.query('url', params.url, {});
      rb.query('slug', params.slug, {});
      rb.query('title', params.title, {});
      rb.query('offset', params.offset, {});
      rb.query('limit', params.limit, {});
      rb.query('type', params.type, {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('sortOrder', params.sortOrder, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ListWebsiteInitialPodcastsResponseItemDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `podcastControllerGetPodcasts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  podcastControllerGetPodcasts(
    params?: {
      website?: string;
      podcastIds?: Array<string>;
      url?: string;
      slug?: string;
      title?: string;
      offset?: number;
      limit?: number;
      type?: PodcastType;
      sortBy?: 'title' | 'createdAt' | 'lastEpisodeSync';
      sortOrder?: 'asc' | 'desc';
    },
    context?: HttpContext
  ): Observable<Array<ListWebsiteInitialPodcastsResponseItemDto>> {
    return this.podcastControllerGetPodcasts$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ListWebsiteInitialPodcastsResponseItemDto>>): Array<ListWebsiteInitialPodcastsResponseItemDto> => r.body)
    );
  }

  /** Path part for operation `podcastControllerCreatePodcastFromUrl()` */
  static readonly PodcastControllerCreatePodcastFromUrlPath = '/api/podcast';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `podcastControllerCreatePodcastFromUrl()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  podcastControllerCreatePodcastFromUrl$Response(
    params: {
      body: CreatePodcastFromUrlDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PodcastDto>> {
    const rb = new RequestBuilder(this.rootUrl, PodcastApi.PodcastControllerCreatePodcastFromUrlPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PodcastDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `podcastControllerCreatePodcastFromUrl$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  podcastControllerCreatePodcastFromUrl(
    params: {
      body: CreatePodcastFromUrlDto
    },
    context?: HttpContext
  ): Observable<PodcastDto> {
    return this.podcastControllerCreatePodcastFromUrl$Response(params, context).pipe(
      map((r: StrictHttpResponse<PodcastDto>): PodcastDto => r.body)
    );
  }

  /** Path part for operation `podcastControllerGetPodcastById()` */
  static readonly PodcastControllerGetPodcastByIdPath = '/api/podcast/podcast/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `podcastControllerGetPodcastById()` instead.
   *
   * This method doesn't expect any request body.
   */
  podcastControllerGetPodcastById$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PodcastDto>> {
    const rb = new RequestBuilder(this.rootUrl, PodcastApi.PodcastControllerGetPodcastByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PodcastDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `podcastControllerGetPodcastById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  podcastControllerGetPodcastById(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<PodcastDto> {
    return this.podcastControllerGetPodcastById$Response(params, context).pipe(
      map((r: StrictHttpResponse<PodcastDto>): PodcastDto => r.body)
    );
  }

  /** Path part for operation `podcastControllerCreateManualPodcast()` */
  static readonly PodcastControllerCreateManualPodcastPath = '/api/podcast/manual-podcast';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `podcastControllerCreateManualPodcast()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  podcastControllerCreateManualPodcast$Response(
    params: {
      body: CreateManualPodcastDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, PodcastApi.PodcastControllerCreateManualPodcastPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `podcastControllerCreateManualPodcast$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  podcastControllerCreateManualPodcast(
    params: {
      body: CreateManualPodcastDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.podcastControllerCreateManualPodcast$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `podcastControllerDeletePodcast()` */
  static readonly PodcastControllerDeletePodcastPath = '/api/podcast/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `podcastControllerDeletePodcast()` instead.
   *
   * This method doesn't expect any request body.
   */
  podcastControllerDeletePodcast$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, PodcastApi.PodcastControllerDeletePodcastPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `podcastControllerDeletePodcast$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  podcastControllerDeletePodcast(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.podcastControllerDeletePodcast$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `podcastControllerUpdatePodcast()` */
  static readonly PodcastControllerUpdatePodcastPath = '/api/podcast/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `podcastControllerUpdatePodcast()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  podcastControllerUpdatePodcast$Response(
    params: {
      id: string;
      body: UpdatePodcastDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, PodcastApi.PodcastControllerUpdatePodcastPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `podcastControllerUpdatePodcast$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  podcastControllerUpdatePodcast(
    params: {
      id: string;
      body: UpdatePodcastDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.podcastControllerUpdatePodcast$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `podcastControllerSyncFeed()` */
  static readonly PodcastControllerSyncFeedPath = '/api/podcast/{id}/sync-feed';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `podcastControllerSyncFeed()` instead.
   *
   * This method doesn't expect any request body.
   */
  podcastControllerSyncFeed$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, PodcastApi.PodcastControllerSyncFeedPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `podcastControllerSyncFeed$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  podcastControllerSyncFeed(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.podcastControllerSyncFeed$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `podcastControllerFeedOverride()` */
  static readonly PodcastControllerFeedOverridePath = '/api/podcast/{id}/feed-override';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `podcastControllerFeedOverride()` instead.
   *
   * This method doesn't expect any request body.
   */
  podcastControllerFeedOverride$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, PodcastApi.PodcastControllerFeedOverridePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `podcastControllerFeedOverride$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  podcastControllerFeedOverride(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.podcastControllerFeedOverride$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `podcastControllerSubmitToPodChaser()` */
  static readonly PodcastControllerSubmitToPodChaserPath = '/api/podcast/{id}/submit-podchaser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `podcastControllerSubmitToPodChaser()` instead.
   *
   * This method doesn't expect any request body.
   */
  podcastControllerSubmitToPodChaser$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, PodcastApi.PodcastControllerSubmitToPodChaserPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `podcastControllerSubmitToPodChaser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  podcastControllerSubmitToPodChaser(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<string> {
    return this.podcastControllerSubmitToPodChaser$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `podcastControllerGetPodcastCategories()` */
  static readonly PodcastControllerGetPodcastCategoriesPath = '/api/podcast/{siteId}/{type}/podcast-categories';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `podcastControllerGetPodcastCategories()` instead.
   *
   * This method doesn't expect any request body.
   */
  podcastControllerGetPodcastCategories$Response(
    params: {
      siteId: string;
      type: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<string>>> {
    const rb = new RequestBuilder(this.rootUrl, PodcastApi.PodcastControllerGetPodcastCategoriesPath, 'get');
    if (params) {
      rb.path('siteId', params.siteId, {});
      rb.path('type', params.type, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `podcastControllerGetPodcastCategories$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  podcastControllerGetPodcastCategories(
    params: {
      siteId: string;
      type: string;
    },
    context?: HttpContext
  ): Observable<Array<string>> {
    return this.podcastControllerGetPodcastCategories$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `podcastControllerListParticipants()` */
  static readonly PodcastControllerListParticipantsPath = '/api/podcast/{siteId}/{id}/participant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `podcastControllerListParticipants()` instead.
   *
   * This method doesn't expect any request body.
   */
  podcastControllerListParticipants$Response(
    params: {
      siteId: string;
      id: string;
      slug?: string;
      status?: 'APPROVED' | 'PENDING' | 'DECLINED';
      role?: string;
      name?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<GetParticipantResponseDto>>> {
    const rb = new RequestBuilder(this.rootUrl, PodcastApi.PodcastControllerListParticipantsPath, 'get');
    if (params) {
      rb.path('siteId', params.siteId, {});
      rb.path('id', params.id, {});
      rb.query('slug', params.slug, {});
      rb.query('status', params.status, {});
      rb.query('role', params.role, {});
      rb.query('name', params.name, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GetParticipantResponseDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `podcastControllerListParticipants$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  podcastControllerListParticipants(
    params: {
      siteId: string;
      id: string;
      slug?: string;
      status?: 'APPROVED' | 'PENDING' | 'DECLINED';
      role?: string;
      name?: string;
    },
    context?: HttpContext
  ): Observable<Array<GetParticipantResponseDto>> {
    return this.podcastControllerListParticipants$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GetParticipantResponseDto>>): Array<GetParticipantResponseDto> => r.body)
    );
  }

  /** Path part for operation `podcastControllerSubmitParticipant()` */
  static readonly PodcastControllerSubmitParticipantPath = '/api/podcast/{id}/participant/{participantId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `podcastControllerSubmitParticipant()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  podcastControllerSubmitParticipant$Response(
    params: {
      id: string;
      participantId: string;
      body: AppendParticipantRequestDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, PodcastApi.PodcastControllerSubmitParticipantPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('participantId', params.participantId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `podcastControllerSubmitParticipant$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  podcastControllerSubmitParticipant(
    params: {
      id: string;
      participantId: string;
      body: AppendParticipantRequestDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.podcastControllerSubmitParticipant$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `podcastControllerRemoveParticipant()` */
  static readonly PodcastControllerRemoveParticipantPath = '/api/podcast/{id}/participant/{participantId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `podcastControllerRemoveParticipant()` instead.
   *
   * This method doesn't expect any request body.
   */
  podcastControllerRemoveParticipant$Response(
    params: {
      id: string;
      participantId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, PodcastApi.PodcastControllerRemoveParticipantPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('participantId', params.participantId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `podcastControllerRemoveParticipant$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  podcastControllerRemoveParticipant(
    params: {
      id: string;
      participantId: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.podcastControllerRemoveParticipant$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
