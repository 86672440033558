/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { PodcastDto } from '../models/podcast-dto';
import { RestrictedPodcastDto } from '../models/restricted-podcast-dto';

@Injectable({ providedIn: 'root' })
export class WebsitePodcastApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `websitePodcastControllerGetById()` */
  static readonly WebsitePodcastControllerGetByIdPath = '/api/website/{siteId}/podcast/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websitePodcastControllerGetById()` instead.
   *
   * This method doesn't expect any request body.
   */
  websitePodcastControllerGetById$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<(PodcastDto | RestrictedPodcastDto)>> {
    const rb = new RequestBuilder(this.rootUrl, WebsitePodcastApi.WebsitePodcastControllerGetByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<(PodcastDto | RestrictedPodcastDto)>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websitePodcastControllerGetById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websitePodcastControllerGetById(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<(PodcastDto | RestrictedPodcastDto)> {
    return this.websitePodcastControllerGetById$Response(params, context).pipe(
      map((r: StrictHttpResponse<(PodcastDto | RestrictedPodcastDto)>): (PodcastDto | RestrictedPodcastDto) => r.body)
    );
  }

}
